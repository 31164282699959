export const getListingStatusName = (status) => {
    switch (status) {
        case 1:
            return 'Not Started';
        case 2:
            return 'Incomplete';
        case 3:
            return 'Submitted';
        case 4:
            return 'Pending';
        case 5:
            return 'Rejected';
        case 6:
            return 'Ready For Escrow Info';
        default:
            return '';
    }
};
