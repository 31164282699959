/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { USERS_KEY } from '~hooks';
import { adminServices } from '~services';
export const useCreateUser = () => {
    const queryClient = useQueryClient();
    const KEY = [USERS_KEY];
    const createUser = async (data) => {
        return { ...data, userId: (await adminServices.users.create(data)).userId };
    };
    const createUserMutation = useMutation(createUser, {
        onSuccess: (updatedUser) => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [updatedUser];
                return [updatedUser, ...cachedData];
            });
            snackbar.success('User successfully created');
        },
        onError: () => {
            snackbar.error('Failed to create user');
        },
    });
    return {
        createUser: async (request) => createUserMutation.mutateAsync(request),
        isCreating: createUserMutation.isLoading,
    };
};
