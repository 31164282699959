/* eslint-disable */
// @ts-nocheck
// disabled for pipeline to fix in next ticket
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Tooltip } from '@chakra-ui/react';
import { Button } from '~components';
import { useListing } from '~hooks';
import { TListingPageMode } from '~pages';
export const ListingProfileSave = ({ isStatusSubmitted, isFormChanged, updateListingMutation, }) => {
    const [listing, isListingLoading] = useListing();
    const { handleSubmit, formState } = useFormContext();
    const isFormDisabled = listing.pageMode === TListingPageMode.FORM_DISABLED;
    const isFormButtonsDisabled = useMemo(() => isFormDisabled || (isStatusSubmitted && !isFormChanged), [isFormDisabled, isFormChanged, isStatusSubmitted]);
    const isSaveButtonDisabled = isListingLoading || !formState.isValid || isFormButtonsDisabled;
    const saveChangesTooltipLabel = useMemo(() => {
        if (isStatusSubmitted && !isFormChanged) {
            return 'Save changes will be enabled after form change';
        }
        if (isFormDisabled || !isSaveButtonDisabled)
            return '';
        return 'Please resolve all validation errors before save changes';
    }, [isFormDisabled, isSaveButtonDisabled, isStatusSubmitted, isFormChanged]);
    const onFormSave = async ({ offer }) => {
        if (formState.isValid) {
            await updateListingMutation({ ...offer });
        }
    };
    return (React.createElement(Tooltip, { hasArrow: true, label: saveChangesTooltipLabel, bg: "red.600", placement: "bottom", shouldWrapChildren: true },
        React.createElement(Button, { disabled: isSaveButtonDisabled, bg: "gray.200", variant: "outline", onClick: handleSubmit(onFormSave) }, "SAVE CHANGES")));
};
