"use strict";
/* eslint-disable */
/* istanbul ignore file */
const plugin = require('tailwindcss/plugin');
module.exports = {
    theme: {
        screens: {
            xs: '340px',
            sm: '720px',
            md: '1024px',
            lg: '1920px',
            xl: '3840px',
            '2xl': '7680px',
        },
        extend: {
            fontFamily: {
                sans: ['Montserrat', 'sans-serif'],
                serif: ['Montserrat', 'sans-serif'],
            },
            colors: {
                primary: 'var(--color-purple-600)',
                gray: {
                    50: '#F4F4F4',
                    100: '#F5F5F5',
                    200: '#E0E0E0',
                    300: '#D4D4D4',
                    400: '#A3A3A3',
                    500: '#737373',
                    600: '#666666',
                    700: '#404040',
                    800: '#262626',
                    900: '#171717',
                },
                purple: {
                    DEFAULT: '#662FA0',
                    50: '#D7C2ED',
                    100: '#CDB2E9',
                    200: '#B893DF',
                    300: '#A373D6',
                    400: '#8F54CD',
                    500: '#7A38C0',
                    600: '#662FA0',
                    700: '#4A2275',
                    800: '#2F1649',
                    900: '#13091E',
                },
                red: {
                    50: '#FEF2F2',
                    100: '#FEE2E2',
                    200: '#FECACA',
                    300: '#FCA5A5',
                    400: '#F87171',
                    500: '#EF4444',
                    600: '#DC2626',
                    700: '#B91C1C',
                    800: '#991B1B',
                    900: '#7F1D1D',
                },
            },
        },
    },
    plugins: [
        require('@tailwindcss/line-clamp'),
        plugin(function ({ addBase, theme }) {
            function extractColorVars(colorObj, colorGroup = '') {
                return Object.keys(colorObj).reduce((vars, colorKey) => {
                    const value = colorObj[colorKey];
                    const newVars = typeof value === 'string'
                        ? { [`--color${colorGroup}-${colorKey}`]: value }
                        : extractColorVars(value, `-${colorKey}`);
                    return { ...vars, ...newVars };
                }, {});
            }
            addBase({
                ':root': extractColorVars(theme('colors')),
            });
        }),
    ],
};
