import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Text } from '~components';
import { ListingDetailsSection, usePreparedListingDetailsSections } from '.';
export const ListingDetails = () => {
    const { firstDetailsSection, secondDetailsSection, thirdDetailsSection } = usePreparedListingDetailsSections();
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { colorScheme: "purple", className: "text-violet-800 font-medium" }, "LISTING DETAILS"),
        React.createElement(Stack, { direction: { base: 'column', md: 'row' }, spacing: 8, mx: "auto", width: "full" },
            React.createElement(ListingDetailsSection, { section: firstDetailsSection }),
            React.createElement(ListingDetailsSection, { section: secondDetailsSection }),
            React.createElement(ListingDetailsSection, { section: thirdDetailsSection }))));
};
