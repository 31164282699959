import React, { useMemo } from 'react';
import { Link } from '@chakra-ui/react';
import { useListing } from '~hooks';
import { TDateFormat } from '~types';
import { isEmpty, tryParseAndFormat } from '~utils';
export const usePreparedListingDetailsSections = () => {
    const [{ details }] = useListing();
    const tourUrlComponent = (tourUrl) => (React.createElement(Link, { className: "text-violet-800", target: "_blank", rel: "noopener", href: tourUrl }, tourUrl ? 'Zillow' : ''));
    const firstDetailsSection = useMemo(() => !isEmpty(details)
        ? [
            {
                label: 'MLS Listing Number',
                value: details.mlsListingId,
            },
            {
                label: 'MLS Status',
                value: details.mlsStatus,
            },
            {
                label: 'Listing Date',
                value: tryParseAndFormat(details.listingDate, TDateFormat.DATE_ONLY),
            },
            {
                label: 'Subdivision',
                value: details.subdivision,
            },
            {
                label: 'List Price (USD)',
                value: details.listPrice,
            },
            {
                label: 'County',
                value: details.county,
            },
            {
                label: 'Tour URL',
                value: tourUrlComponent(details.tourUrl),
            },
            {
                label: 'Listing Source',
                value: details.listingSource,
            },
        ]
        : [], [details]);
    const secondDetailsSection = useMemo(() => !isEmpty(details)
        ? [
            {
                label: 'Property Type',
                value: details.propertyType,
            },
            {
                label: 'Beds',
                value: details.beds,
            },
            {
                label: 'Baths',
                value: details.baths,
            },
            {
                label: 'Living Area Sqft',
                value: details.livingArea,
            },
            {
                label: 'Year Built',
                value: details.yearBuilt,
            },
            {
                label: 'Lot Sqft',
                value: details.lotSizeSquareFeet,
            },
        ]
        : [], [details]);
    const thirdDetailsSection = useMemo(() => !isEmpty(details)
        ? [
            {
                label: 'Builder',
                value: details.builder,
            },
            {
                label: 'Deal',
                value: details.deal,
            },
            {
                label: 'Phase',
                value: details.phase,
            },
        ]
        : [], [details]);
    return {
        firstDetailsSection,
        secondDetailsSection,
        thirdDetailsSection,
    };
};
