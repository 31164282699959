/* eslint-disable @typescript-eslint/naming-convention */
/* istanbul ignore file */
import { extendTheme } from '@chakra-ui/react';
import { ButtonTheme as Button, HeadingTheme as Heading, InputTheme as Input, ModalTheme as Modal, NumberInputTheme as NumberInput, TextTheme as Text, } from '~components';
import { theme as tailwindTheme } from './tailwind.theme';
export const theme = extendTheme({
    name: 'default',
    colors: tailwindTheme.extend.colors,
    breakpoints: tailwindTheme.screens,
    fonts: {
        body: 'Montserrat, sans-serif',
        heading: 'Montserrat, sans-serif',
    },
    styles: {
        global: {
            'html, body': {
                lineHeight: 'tall',
                fontSize: '14px',
            },
        },
    },
    components: {
        Modal,
        Input,
        NumberInput,
        Button,
        Heading,
        Text,
    },
});
