import { axiosAuthorizedInstance, dataFromResponse, getApiBasePath } from '~services';
export async function getAll() {
    const url = `${getApiBasePath()}/admin/brokerages`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
export async function create(request) {
    const url = `${getApiBasePath()}/admin/brokerages`;
    return dataFromResponse(axiosAuthorizedInstance.post(url, request));
}
export async function update(request, brokerageId) {
    const url = `${getApiBasePath()}/admin/brokerages/${brokerageId}`;
    return axiosAuthorizedInstance.put(url, request);
}
