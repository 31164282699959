import React from 'react';
import { Stack } from '@chakra-ui/react';
export const ButtonBlock = ({ full, width = '100%', direction, justify, hidden, className, children }) => {
    const justifyContent = () => {
        if (direction === 'row')
            return justify ?? 'end';
        if (full)
            return 'stretch';
        return 'end';
    };
    const alignItems = () => {
        if (direction === 'row')
            return 'left';
        if (full)
            return 'stretch';
        return 'start';
    };
    if (!children)
        return null;
    return (React.createElement(Stack, { width: width, className: className, style: { gap: '0.5rem' }, hidden: hidden, direction: direction, justifyContent: justifyContent(), alignItems: alignItems() }, children));
};
