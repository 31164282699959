import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getAuthToken, removeAuthToken } from '~services';
import { TErrorReason } from '~types';
import { mapError } from './mapError';
export const axiosAuthorizedInstance = axios.create();
axiosAuthorizedInstance.cancelToken = axios.CancelToken;
axiosAuthorizedInstance.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, async (error) => {
    return Promise.reject(error);
});
axiosAuthorizedInstance.interceptors.response.use((response) => {
    // Any status code 2xx trigger
    return response;
}, async (error) => {
    const mappedError = mapError(error);
    return error.response.status === 401 ? Promise.reject(error) : Promise.reject(mappedError);
});
const refreshAuthLogic = async () => {
    // Refresh logic is not implemented but we can separate token expiration from unauthorized error response
    removeAuthToken();
    return Promise.reject(new Error(TErrorReason.TOKEN_EXPIRED));
};
createAuthRefreshInterceptor(axiosAuthorizedInstance, refreshAuthLogic);
