import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.less';
import { App } from './App';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');
if (rootElement !== null) {
    const root = createRoot(rootElement);
    root.render(React.createElement(App, { baseUrl: baseUrl }));
}
