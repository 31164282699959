import React, { memo } from 'react';
import { Stack } from '@chakra-ui/react';
import { Form, Text } from '~components';
import { TOccupancy } from '~types';
const OCCUPANCY_OPTIONS = [
    { label: 'Occupied', value: TOccupancy.OCCUPIED },
    { label: 'Vacant', value: TOccupancy.VACANT },
    { label: 'Occupancy Unknown', value: TOccupancy.OCCUPANCY_UNKNOWN },
];
export const AdditionalInformation = memo(({ disabled }) => {
    return (React.createElement(Stack, { direction: "column", width: "full" },
        React.createElement(Text, { colorScheme: "purple", className: "font-medium text-violet-800" }, "ADDITIONAL INFORMATION"),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.electricProvider", label: "Electric", size: "xs", isRequired: true }),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.gasProvider", label: "Gas", size: "xs", isRequired: true }),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.sewerSepticProvider", label: "Sewer / Septic", size: "xs", isRequired: true }),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.wellWaterProvider", label: "Well / City Water", size: "xs", isRequired: true }),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.lockBox", label: "Lockbox", size: "xs", isRequired: true }),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.accessMethod", label: "Access Method", size: "xs", isRequired: true }),
        React.createElement(Form.Text, { disabled: disabled, name: "offer.gateCode", label: "Gate Code", size: "xs" }),
        React.createElement(Form.Select, { disabled: disabled, name: "offer.occupancy", label: "Occupancy", size: "xs", isRequired: true }, OCCUPANCY_OPTIONS.map(({ label, value }) => {
            return (React.createElement("option", { value: value, key: value }, label));
        }))));
});
