import { useState } from 'react';
export * from './ConfirmModal';
export * from './ModalBase';
function useModalProps(callback) {
    const [isVisible, setIsVisible] = useState(false);
    const [options, setOptions] = useState();
    const show = () => {
        setIsVisible(true);
    };
    const hide = () => {
        setIsVisible(false);
    };
    function createModal(resolve) {
        setOptions({ resolve });
        show();
    }
    const reset = () => {
        hide();
        setOptions(undefined);
    };
    const submit = (value) => {
        runCallback(callback, value);
        options?.resolve(value);
        reset();
    };
    const reject = () => {
        runCallback(callback, undefined);
        options?.resolve(undefined);
        reset();
    };
    const modal = async () => new Promise(createModal);
    return {
        modalControls: { isVisible, submit, reject },
        modal,
        reject,
    };
}
export function useModal(callback) {
    const { modalControls, modal, reject } = useModalProps(callback);
    return [{ controls: modalControls }, modal, reject];
}
function runCallback(callback, value) {
    if (!callback || typeof callback !== 'function')
        return;
    void callback(value);
}
