import { createColumnHelper } from '@tanstack/react-table';
import { uuid } from '~utils';
function defaultCellRenderer(info) {
    return info.renderValue();
}
function emptyAccessor() { }
export function buildTableColumns(columnsDefinition) {
    const columnHelper = createColumnHelper();
    if (!columnsDefinition)
        return [];
    return columnsDefinition.map(([accessor, column]) => columnHelper.accessor(accessor ?? emptyAccessor, typeof column === 'string'
        ? {
            id: uuid(),
            cell: defaultCellRenderer,
            header: column,
        }
        : {
            ...column,
            id: column.id ?? uuid(),
            cell: column.cell ?? defaultCellRenderer,
            header: typeof column.header === 'string' ? () => column.header : column.header,
        }));
}
