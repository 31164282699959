/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
export const FormField = (props) => {
    const { control } = useFormContext();
    const { render, name, defaultValue = '', validationRules = {}, isRequired, label, labelPosition = 'left', helperText, className, maxWidth, minWidth, width, fieldWidth, } = props;
    return (React.createElement(Controller, { control: control, name: name, defaultValue: defaultValue, rules: validationRules, render: ({ field: { onChange, onBlur, ref, value }, fieldState: { error } }) => {
            return (React.createElement(FormControl, { minWidth: minWidth, isInvalid: !!error, isRequired: isRequired, style: { width: fieldWidth ?? 'auto' }, className: className, as: Flex, flexDirection: labelPosition === 'left' ? 'row' : 'column', justifyContent: labelPosition === 'left' ? 'space-between' : 'normal', alignItems: labelPosition === 'left' ? 'baseline' : 'stretch' },
                label ? React.createElement(FormLabel, { fontWeight: "600" }, label) : null,
                React.createElement(Flex, { flexDirection: "column" },
                    render({ onChange, onBlur, ref, value, error }),
                    React.createElement(FormErrorMessage, { maxWidth: maxWidth ?? width }, error?.message),
                    !!helperText && React.createElement(FormHelperText, null, helperText))));
        } }));
};
