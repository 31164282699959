import React from 'react';
import { Text as ChakraText } from '@chakra-ui/react';
export const Text = (props) => {
    const { children, ...restOfTextProps } = props;
    return React.createElement(ChakraText, { ...restOfTextProps }, children);
};
export const TextTheme = {
    sizes: {
        lg: {
            fontSize: 'xl',
        },
        base: {
            fontSize: 'md',
        },
    },
};
