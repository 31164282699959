import React from 'react';
import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Spinner, Text } from '~components';
export const DataTable = ({ columns, data, isLoading, noDataMessage, isRowHighlightedFn }) => {
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        meta: {
            getRowStyles: (row) => (isRowHighlightedFn?.(row.original) ? { backgroundColor: 'var(--color-violet-100)' } : {}),
        },
    });
    return (React.createElement(Spinner, { isLoading: isLoading, className: "grow" },
        React.createElement(Flex, { width: "full", flexDirection: "column", height: "100%" },
            React.createElement(Table, { size: "sm", colorScheme: "purple" },
                React.createElement(Thead, null, table.getHeaderGroups().map((headerGroup) => (React.createElement(Tr, { key: headerGroup.id }, headerGroup.headers.map((header) => (React.createElement(Th, { userSelect: "none", key: header.id }, header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())))))))),
                React.createElement(Tbody, null, table.getRowModel().rows.map((row) => (
                // @ts-expect-error Property 'getRowStyles' does not exist on type 'TableMeta'
                React.createElement(Tr, { key: row.id, style: { ...table.options.meta.getRowStyles(row) }, "data-testid": row.id }, row.getVisibleCells().map((cell) => (React.createElement(Td, { key: cell.id, "data-testid": cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext()))))))))),
            !isLoading && !table.getRowModel().rows.length ? (React.createElement(Flex, { width: "full", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "300px" },
                React.createElement(Text, null, noDataMessage))) : null)));
};
