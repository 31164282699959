import React, { memo } from 'react';
import { Stack } from '@chakra-ui/react';
import { Form, Text } from '~components';
export const ContractInformation = memo(({ disabled }) => (React.createElement(Stack, { direction: "column", width: "full" },
    React.createElement(Text, { colorScheme: "purple", className: "text-violet-800 font-medium " }, "CONTRACT INFORMATION"),
    React.createElement(Form.DateTimePicker, { disabled: disabled, name: "offer.initialOfferDate", label: "Initial Offer Date", size: "xs", isRequired: true }),
    React.createElement(Form.DateTimePicker, { disabled: disabled, name: "offer.offerBindingDate", label: "Offer Binding Date", size: "xs", isRequired: true }),
    React.createElement(Form.Currency, { disabled: disabled, name: "offer.finalPurchasePrice", label: "Final Purchase Price", size: "xs", isRequired: true }),
    React.createElement(Form.Text, { disabled: disabled, name: "offer.listingAgentName", label: "Listing Agent Name", size: "xs", isRequired: true }),
    React.createElement(Form.Text, { disabled: disabled, name: "offer.listingAgentEmail", label: "Listing Agent Email", size: "xs", isRequired: true }),
    React.createElement(Form.Phone, { disabled: disabled, name: "offer.listingAgentPhone", label: "Listing Agent Phone", size: "xs", isRequired: true }),
    React.createElement(Form.Text, { disabled: disabled, name: "offer.sellerName", label: "Seller Name", size: "xs", isRequired: true }))));
