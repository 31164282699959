import React, { memo } from 'react';
import { Stack } from '@chakra-ui/react';
import { Form, Text } from '~components';
import { THasHoa, THoaFrequency } from '~types';
const HAS_HOA_OPTIONS = [
    { label: 'Yes', value: THasHoa.YES },
    { label: 'No', value: THasHoa.NO },
    { label: '', value: THasHoa.UNKNOWN },
];
export const HoaInformation = memo(({ disabled, hasHOA }) => {
    const hoaFrequencyOptions = Object.values(THoaFrequency);
    return (React.createElement(Stack, { flexDirection: "column", width: "full" },
        React.createElement(Text, { colorScheme: "purple", className: "font-medium text-violet-800" }, "HOA INFORMATION"),
        React.createElement(Form.Select, { disabled: disabled, name: "offer.hasHOA", label: "Has HOA?", size: "xs" }, HAS_HOA_OPTIONS.map(({ label, value }) => {
            return (React.createElement("option", { value: value, key: value, hidden: value === THasHoa.UNKNOWN }, label));
        })),
        hasHOA ? (React.createElement(React.Fragment, null,
            React.createElement(Form.Text, { disabled: disabled, name: "offer.hoaName", label: "HOA Name", size: "xs", isRequired: true }),
            React.createElement(Form.Text, { disabled: disabled, name: "offer.hoaWebsite", label: "HOA Website", size: "xs", isRequired: true }),
            React.createElement(Form.Phone, { disabled: disabled, name: "offer.hoaPhone", label: "HOA Phone", size: "xs", isRequired: true }),
            React.createElement(Form.Currency, { disabled: disabled, name: "offer.hoaTransferFee", label: "HOA Transfer Fee", size: "xs", isRequired: true }),
            React.createElement(Form.Currency, { disabled: disabled, name: "offer.hoaFee", label: "HOA Fee", size: "xs", isRequired: true }),
            React.createElement(Form.Select, { disabled: disabled, name: "offer.hoaFrequency", label: "HOA Frequency", size: "xs", isRequired: true }, hoaFrequencyOptions.map((item) => (React.createElement("option", { value: item, key: item, hidden: !item }, item)))))) : null));
});
