import { useForm } from 'react-hook-form';
import { useFormValidationResolver } from '~hooks';
import { listingProfileValidationSaveSchema } from '~pages/Listings/ListingProfilePage/listing-profile.validation';
import { THasHoa } from '~types';
export const useListingDetailsForm = (isFormDisabled) => {
    const [validationResolver] = useFormValidationResolver(listingProfileValidationSaveSchema);
    const formMethods = useForm({
        mode: 'onChange',
        resolver: !isFormDisabled ? async (data) => validationResolver(formMethods, data) : undefined,
    });
    return formMethods;
};
export const toListingProfileFormModel = (listing) => {
    const { offer, details } = listing;
    const getListingDateField = (dateInSting) => (!dateInSting ? new Date() : new Date(dateInSting));
    const hoaStingValue = offer.hasHOA ? THasHoa.YES : THasHoa.NO;
    return {
        offer: {
            id: offer.id,
            listingId: offer.listingId,
            initialOfferDate: getListingDateField(offer.initialOfferDate),
            offerBindingDate: getListingDateField(offer.offerBindingDate),
            finalPurchasePrice: offer.finalPurchasePrice || 0,
            listingAgentName: offer.listingAgentName || '',
            listingAgentPhone: offer.listingAgentPhone || '',
            listingAgentEmail: offer.listingAgentEmail || '',
            sellerName: offer.sellerName || '',
            titleCompany: details.titleCompany || '',
            emdDeadline: getListingDateField(offer.emdDeadline),
            dueDiligenceExpirationDate: getListingDateField(offer.dueDiligenceExpirationDate),
            closeOfEscrowDate: getListingDateField(offer.closeOfEscrowDate),
            dueDiligenceStartDate: getListingDateField(offer.dueDiligenceStartDate),
            commissionTotal: offer.commissionTotal || 0,
            earnestDepositAmount: offer.earnestDepositAmount || 0,
            lockBox: offer.lockBox || '',
            accessMethod: offer.accessMethod || '',
            wellWaterProvider: offer.wellWaterProvider || '',
            electricProvider: offer.electricProvider || '',
            sewerSepticProvider: offer.sewerSepticProvider || '',
            gasProvider: offer.gasProvider || '',
            status: offer.status,
            occupancy: offer.occupancy ?? 3,
            gateCode: offer.gateCode ?? '',
            hasHOA: offer.hasHOA === null ? THasHoa.UNKNOWN : hoaStingValue,
            hoaName: offer.hoaName || '',
            hoaPhone: offer.hoaPhone || '',
            hoaWebsite: offer.hoaWebsite || '',
            hoaTransferFee: offer.hoaTransferFee || 0,
            hoaFee: offer.hoaFee || 0,
            hoaFrequency: offer.hoaFrequency || '',
        },
    };
};
