import { css, Radio as ChakraRadio, RadioGroup as ChakraRadioGroup, Stack, useTheme, } from '@chakra-ui/react';
// eslint-disable-next-line no-unused-vars
import { jsx } from '@emotion/react';
import { getKey } from '~utils';
export const RadioGroup = (props) => {
    const { size = 'md', variant = 'outline', direction = 'column', controlData, className, ...restOfRadioGroupProps } = props;
    const theme = useTheme();
    const styles = (isDisabled) => css({
        '--chakra-shadows-outline': 'var(--color-purple-100) 0px 0px 0px 3px!important',
        '&.chakra-radio__control': {
            borderColor: !isDisabled ? 'purple.300' : 'grey.100',
            backgroundColor: !isDisabled ? '' : 'grey.50',
        },
    })(
    // @ts-expect-error lack of examples of generic useTheme
    theme);
    return (jsx(ChakraRadioGroup, { className: `rounded-none ${className}`, ...{ size, variant }, ...restOfRadioGroupProps }, controlData.length ? (jsx(Stack, { direction: direction }, controlData.map((item, index) => (jsx(ChakraRadio, { "aria-label": item.value, colorScheme: "purple", sx: styles(item.isDisabled), key: getKey(item, index), value: item.key, isDisabled: item.isDisabled }, item.value))))) : null));
};
