/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import { Button, ButtonBlock, Form } from '~components';
import { useRouting } from '~hooks';
import { AuthLogo } from '~pages';
import { resetPassword } from '~services';
import { isApiError } from '~types';
export const NewPasswordPage = () => {
    const { navigate, search } = useRouting();
    const { email, token } = search;
    const formMethods = useForm({ mode: 'onChange' });
    const { handleSubmit, setError } = formMethods;
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!email || !token) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const compareFields = (form) => {
        return form.password === form.confirmPassword;
    };
    const showEqualError = () => {
        setError('password', {
            type: 'custom',
            message: 'Passwords must be equial',
        });
    };
    const onSubmit = async (form) => {
        const areFieldsAreEquial = compareFields(form);
        if (!areFieldsAreEquial) {
            showEqualError();
            return;
        }
        try {
            setIsLoading(true);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await resetPassword(email, token, form.password);
            navigate('/login');
        }
        catch (e) {
            if (isApiError(e)) {
                setError('password', {
                    type: 'Request error',
                    message: e.message,
                });
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    return (React.createElement(Flex, { flexGrow: 1, align: "center", justify: "center" },
        React.createElement(Stack, { spacing: 8, mx: "auto", width: 600, py: 12, px: 6 },
            React.createElement(Box, { rounded: "20px", bg: useColorModeValue('white', 'gray.700'), p: 8 },
                React.createElement(AuthLogo, { className: "pb-2 mx-auto" }),
                React.createElement(Heading, { as: "h6", size: "lg", textAlign: "center", className: "text-violet-800", p: 5 }, "Update your Password"),
                React.createElement(FormProvider, { ...formMethods },
                    React.createElement(Stack, { spacing: 4, as: "form", onSubmit: handleSubmit(onSubmit) },
                        React.createElement(Form.Password, { name: "password", variant: "rounded", placeholder: "Enter your new Password", label: "Your new Password", labelPosition: "top", isRequired: true }),
                        React.createElement(Form.Password, { name: "confirmPassword", variant: "rounded", placeholder: "Confirm your new Password", label: "Your new Password Confirmation", labelPosition: "top", isRequired: true }),
                        React.createElement(ButtonBlock, null,
                            React.createElement(ButtonBlock, { direction: "row", justify: "center" },
                                React.createElement(Button, { isLoading: isLoading, type: "submit", variant: "solid-rounded", width: 355 }, "Update Password")))))))));
};
