import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getQueryMethods, getQueryStatus, useRouting } from '~hooks';
import { listingsService } from '~services';
export const LISTING_KEY = 'listing';
export const getListingKey = (listingId) => [LISTING_KEY, listingId];
export function useListing() {
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const KEY = getListingKey(listingId);
    const isEnabled = !!listingId;
    const query = useQuery(KEY, async () => listingsService.get(listingId), {
        enabled: isEnabled,
    });
    const result = query.data ?? {};
    const queryStatus = getQueryStatus(query);
    return [result, isEnabled && queryStatus.isLoading, { ...getQueryMethods(query, queryClient, KEY), ...queryStatus }];
}
