/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Button, ButtonBlock, Form } from '~components';
import { AuthLogo } from '~pages';
import { requestResetPassword } from '~services';
import { isApiError, TApiErrorCode } from '~types';
export const ForgotPasswordPage = () => {
    const formMethods = useForm({ mode: 'onChange' });
    const { handleSubmit, setError } = formMethods;
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestSent, setIsRequestSent] = useState(false);
    const onSubmit = async (form) => {
        try {
            setIsLoading(true);
            await requestResetPassword(form.email);
            setIsRequestSent(true);
        }
        catch (e) {
            if (isApiError(e)) {
                if (e.errorCode === TApiErrorCode.UNKNOWN_SIGN_IN_EMAIL) {
                    setError('email', {
                        type: 'custom',
                        message: 'Email not found',
                    });
                    return;
                }
            }
            else {
                throw e;
            }
        }
        finally {
            setIsLoading(false);
        }
    };
    return (React.createElement(Flex, { flexGrow: 1, align: "center", justify: "center" },
        React.createElement(Stack, { spacing: 8, mx: "auto", width: 600, py: 12, px: 6 },
            React.createElement(Box, { rounded: "20px", bg: useColorModeValue('white', 'gray.700'), p: 8 },
                React.createElement(AuthLogo, { className: "pb-2 mx-auto" }),
                !isRequestSent ? (React.createElement(React.Fragment, null,
                    React.createElement(Heading, { as: "h6", size: "lg", textAlign: "center", className: "text-violet-800", p: 5 }, "Request a password reset be emailed to you"),
                    React.createElement(FormProvider, { ...formMethods },
                        React.createElement(Stack, { spacing: 4, as: "form", onSubmit: handleSubmit(onSubmit) },
                            React.createElement(Form.Email, { name: "email", placeholder: "Enter your Email", label: "Your Email", labelPosition: "top", variant: "rounded", isRequired: true }),
                            React.createElement(ButtonBlock, { direction: "row" },
                                React.createElement(Button, { alignLeft: true, variant: "link", to: "/login" }, "Return to Sign In"),
                                React.createElement(Button, { isLoading: isLoading, type: "submit", variant: "solid-rounded" }, "Submit Request")))))) : (React.createElement(React.Fragment, null,
                    React.createElement(Heading, { as: "h6", size: "lg", textAlign: "center", className: "text-violet-800", p: 5 }, "Password Reset Email Sent"),
                    React.createElement(Text, { fontSize: "lg", align: "center", className: "text-violet-800", marginBottom: 10 }, "Please follow the instructions in the email to reset your password."),
                    React.createElement(ButtonBlock, { direction: "row", justify: "center" },
                        React.createElement(Button, { to: "/login" }, "Return to Sign In"))))))));
};
