import { useContext } from 'react';
import { LayoutContext } from '~components';
import { TLayoutBreakpoint } from '~types';
export function useLayout() {
    const layoutContext = useContext(LayoutContext);
    const isGreaterThan = (breakpoint) => {
        switch (breakpoint) {
            case TLayoutBreakpoint.MOBILE:
                return !layoutContext.isMobile;
            case TLayoutBreakpoint.TABLET:
                return !layoutContext.isMobile && !layoutContext.isTablet;
            case TLayoutBreakpoint.LAPTOP:
                return !!layoutContext.isDesktop;
            case TLayoutBreakpoint.DESKTOP:
                return false;
            default:
                return false;
        }
    };
    const isLessThan = (breakpoint) => {
        switch (breakpoint) {
            case TLayoutBreakpoint.MOBILE:
                return false;
            case TLayoutBreakpoint.TABLET:
                return !!layoutContext.isMobile;
            case TLayoutBreakpoint.LAPTOP:
                return !layoutContext.isLaptop && !layoutContext.isDesktop;
            case TLayoutBreakpoint.DESKTOP:
                return !layoutContext.isDesktop;
            default:
                return false;
        }
    };
    return { ...layoutContext, isGreaterThan, isLessThan };
}
