import React, { memo } from 'react';
import { TiDelete } from 'react-icons/ti';
import { Progress, Stack } from '@chakra-ui/react';
import { Img, Text } from '~components';
const FileThumbnailComponent = (props) => {
    const { name, isLoading, isReadonly, onRemove, docTypeName, docTypeOther } = props;
    const remove = (e) => {
        e.stopPropagation();
        if (isReadonly)
            return;
        onRemove();
    };
    return (React.createElement(Stack, { className: `${!isReadonly ? 'group' : ''} relative w-[80px] justify-center`, onClick: remove },
        React.createElement(TiDelete, { size: "3em", className: "absolute hidden top-[70px] left-1/2 translate-center group-hover:block" }),
        React.createElement(Img, { className: "object-cover group-hover:opacity-50", width: "80px", height: "107px", src: `/file-types/${getImageName(name)}.svg`, alt: "doc", opacity: isLoading ? '0.5' : 1 }),
        isLoading ? React.createElement(Progress, { width: "100%", hasStripe: true, size: "xs", isIndeterminate: true, colorScheme: "purple" }) : null,
        React.createElement(Text, { className: "overflow-hidden text-xs text-center text-ellipsis line-clamp-2" }, docTypeOther ?? docTypeName ?? getFileNameWithoutExtension(name))));
};
export const FileThumbnail = memo(FileThumbnailComponent);
function getFileNameWithoutExtension(fileName = '') {
    return fileName.replace(/\.[^/.]+$/, '');
}
function getExtension(fileName) {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileName)?.[0]?.replace('.', '') ?? '';
}
function getImageName(fileName) {
    switch (getExtension(fileName)) {
        case 'doc':
        case 'docx':
        case 'txt':
        case 'rtf':
            return 'doc';
        case 'xls':
        case 'xlsx':
        case 'csv':
            return 'xls';
        case 'pdf':
            return 'pdf';
        case 'rar':
        case 'zip':
            return 'zip';
        case 'png':
        case 'gif':
        case 'webp':
        case 'heic':
        case 'heif':
        case 'psd':
        case 'jpg':
        case 'jpeg':
            return 'image';
        default:
            return 'blank';
    }
}
