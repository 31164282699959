import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Heading, Text } from '~components';
export const ListingsErrorPage = () => {
    return (React.createElement(Stack, { className: "w-full self-center flex flex-col text-center justify-center items-center min-h-[250px] sm:min-h-[300px] h-[70vh] sm:h-[60vh]" },
        React.createElement(Stack, { alignItems: "center" },
            React.createElement(Heading, null,
                React.createElement("p", null, "Oops! Something went wrong.")),
            React.createElement(Text, null, "Please select your company"))));
};
