import React, { createContext } from 'react';
import { createMedia } from '@artsy/fresnel';
import { TLayoutBreakpoint } from '~types';
export const { MOBILE } = TLayoutBreakpoint;
export const { TABLET } = TLayoutBreakpoint;
export const { LAPTOP } = TLayoutBreakpoint;
export const { DESKTOP } = TLayoutBreakpoint;
export const LAYOUT_MIN_WIDTH = {
    [MOBILE]: 0,
    [TABLET]: 720,
    [LAPTOP]: 1024,
    [DESKTOP]: 1920,
};
export const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: LAYOUT_MIN_WIDTH[MOBILE],
        tablet: LAYOUT_MIN_WIDTH[TABLET],
        laptop: LAYOUT_MIN_WIDTH[LAPTOP],
        desktop: LAYOUT_MIN_WIDTH[DESKTOP],
    },
});
export const LayoutContext = createContext({
    isMobile: false,
    isTablet: false,
    isLaptop: false,
    isDesktop: false,
    layoutClassName: '',
});
const LayoutContent = (props) => {
    const { children, ...contextProps } = props;
    return React.createElement(LayoutContext.Provider, { value: contextProps }, children);
};
export const Layout = ({ children, ...mediaProps }) => (React.createElement(Media, { ...mediaProps }, (_, renderChildren) => (renderChildren ? children : null)));
export const MobileLayout = ({ children }) => (React.createElement(LayoutContent, { isMobile: true, layoutClassName: `${MOBILE} min-h-screen` }, children));
export const TabletLayout = ({ children }) => (React.createElement(LayoutContent, { isTablet: true, layoutClassName: `${TABLET} min-h-screen` }, children));
export const LaptopLayout = ({ children }) => (React.createElement(LayoutContent, { isLaptop: true, layoutClassName: `${LAPTOP} min-h-screen` }, children));
export const DesktopLayout = ({ children }) => (React.createElement(LayoutContent, { isDesktop: true, layoutClassName: `${DESKTOP} min-h-screen` }, children));
export const LayoutContextProvider = ({ children, className, layoutOverride }) => {
    const LayoutOverride = layoutOverride;
    return (React.createElement("div", { className: className }, LayoutOverride ? (React.createElement(MediaContextProvider, null,
        React.createElement(Layout, { greaterThanOrEqual: MOBILE },
            React.createElement(LayoutOverride, null, children)))) : (React.createElement(MediaContextProvider, null,
        React.createElement(Layout, { at: MOBILE },
            React.createElement(MobileLayout, null, children)),
        React.createElement(Layout, { at: TABLET },
            React.createElement(TabletLayout, null, children)),
        React.createElement(Layout, { at: LAPTOP },
            React.createElement(LaptopLayout, null, children)),
        React.createElement(Layout, { greaterThanOrEqual: DESKTOP },
            React.createElement(DesktopLayout, null, children))))));
};
