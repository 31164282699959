/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Modal } from '~components/Modal/ModalBase';
export const ConfirmModal = (props) => {
    const { ok, cancel, controls, actions, width = 600, ...restOfProps } = props;
    const onReject = () => {
        controls.reject();
    };
    const defaultActions = [
        {
            title: ok ?? 'YES',
            variant: 'outline',
            type: 'submit',
        },
        {
            title: cancel ?? 'NO',
            variant: 'solid',
            type: 'reject',
            onClick: onReject,
        },
    ];
    return React.createElement(Modal, { ...restOfProps, actions: actions ?? defaultActions, controls: controls, width: width });
};
