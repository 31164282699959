import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getListingKey, useListingModel, useRouting } from '~hooks';
import { listingsService } from '~services';
export function useAcceptListing() {
    const { toRequestObject } = useListingModel();
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const LISTING_KEY = getListingKey(listingId);
    const updateListing = async (offer) => {
        const requestData = toRequestObject(offer);
        const { id, status, pageMode, hasError, errorMessage } = await listingsService.postOfferAccept(requestData);
        return { ...requestData, id, status, hasError, pageMode, errorMessage };
    };
    return useMutation(updateListing, {
        onSuccess: (updatedOfferData) => {
            queryClient.setQueryData(LISTING_KEY, (cachedData) => {
                if (!cachedData)
                    return undefined;
                return { ...cachedData, offer: { ...cachedData.offer, ...updatedOfferData }, pageMode: updatedOfferData.pageMode };
            });
            if (updatedOfferData.hasError) {
                snackbar.error(updatedOfferData.errorMessage);
            }
            else {
                snackbar.success('Listing Successfully Accepted');
            }
        },
        onError: () => {
            snackbar.error('Listing Accepted Operation Failed. Try Again Later');
        },
    });
}
