import { isApiError, TApiErrorCode } from '~types';
export function mapError(error) {
    if (!isApiError(error.response.data)) {
        return {
            data: {},
            message: `Oops, something went wrong! Please refresh or close your app and log in using your username and password.`,
            errorCode: TApiErrorCode.SERVER_UNAVAILABLE,
        };
    }
    return error.response.data;
}
