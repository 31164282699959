import { useQuery, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getQueryMethods, getQueryStatus, useAuth } from '~hooks';
import { adminServices } from '~services';
import { TUserRole } from '~types';
export const BROKERAGES_KEY = 'brokerages';
export const getBrokeragesKey = () => [BROKERAGES_KEY];
export function useBrokerages() {
    const { role } = useAuth();
    const queryClient = useQueryClient();
    const KEY = getBrokeragesKey();
    const isEnabled = role === TUserRole.ADMIN;
    const query = useQuery(KEY, adminServices.brokerages.getAll, {
        enabled: isEnabled,
        onError: () => {
            snackbar.error('Cannot fetch companies at the moment');
        },
    });
    const result = query.data ?? [];
    const status = getQueryStatus(query);
    return [result, status.isLoading, { ...getQueryMethods(query, queryClient, KEY), ...status }];
}
