/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { IoBusinessOutline } from 'react-icons/io5';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { TbUsers } from 'react-icons/tb';
import { Outlet, useOutletContext } from 'react-router';
import { Flex } from '@chakra-ui/react';
import { Button, ButtonBlock, Text } from '~components';
import { useAuth, useRouting } from '~hooks';
import { TUserRole } from '~types';
import { AdminBrokerageForm } from './admin-brokerage.form';
const SidebarContent = () => {
    const { navigate } = useRouting();
    const { role, name, email, brokerageName, logout } = useAuth({
        onLogout: () => {
            navigate('/login');
        },
    });
    const onDownloadFile = () => {
        window.open('/Instructions.pdf', '_blank', 'noopener,noreferrer');
    };
    return (React.createElement(Flex, { h: "full", flexDirection: "column", justifyContent: "space-between" },
        React.createElement(Flex, { flexDirection: "column", className: "pt-4 p-[10px]" },
            role === TUserRole.ADMIN && React.createElement(Text, { fontWeight: "bold" }, "Admin"),
            React.createElement(Text, { fontWeight: "bold", className: "overflow-hidden text-ellipsis" }, name ?? email),
            role === TUserRole.ADMIN ? React.createElement(AdminBrokerageForm, null) : React.createElement(Text, { fontWeight: "bold" }, brokerageName)),
        React.createElement(ButtonBlock, { className: "p-2" },
            React.createElement(Button, { to: "/listings", variant: "link", icon: React.createElement(AiOutlineSearch, null), colorScheme: "white", "data-testid": "listings-link" }, "Search"),
            React.createElement(Button, { onClick: onDownloadFile, variant: "link", icon: React.createElement(IoIosInformationCircleOutline, null), colorScheme: "white", hidden: role === TUserRole.ADMIN, "data-testid": "information-link" }, "Information"),
            React.createElement(Button, { to: "/users", variant: "link", icon: React.createElement(TbUsers, null), colorScheme: "white", hidden: role !== TUserRole.ADMIN, "data-testid": "users-link" }, "Users"),
            React.createElement(Button, { to: "/companies", variant: "link", icon: React.createElement(IoBusinessOutline, null), colorScheme: "white", hidden: role !== TUserRole.ADMIN, "data-testid": "companies-link" }, "Companies"),
            React.createElement(Button, { className: "mt-2", variant: "link", icon: React.createElement(RiLogoutBoxLine, null), onClick: logout, colorScheme: "white", "data-testid": "sign-out-link" }, "Sign out"))));
};
export const HomePage = () => {
    const { setSideBarContent } = useOutletContext();
    useEffect(() => {
        setSideBarContent(React.createElement(SidebarContent, null));
        return () => {
            setSideBarContent(null);
        };
    }, [setSideBarContent]);
    return (React.createElement("div", { className: "flex w-full grow" },
        React.createElement(Flex, { width: "full", padding: "20px" },
            React.createElement(Outlet, null))));
};
