/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getFilesKey, useRouting } from '~hooks';
import { fileService } from '~services';
export const useRemoveFile = () => {
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const KEY = getFilesKey(listingId);
    const removeFile = async (file) => {
        await fileService.remove(file.id);
        return file;
    };
    const removeFileMutation = useMutation(removeFile, {
        onMutate: async (removedFile) => {
            await queryClient.cancelQueries(KEY);
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [];
                return [...cachedData.filter((file) => file.id !== removedFile.id)];
            });
        },
        onSuccess: (removedFile) => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [];
                return [...cachedData.filter((file) => file.id !== removedFile.id)];
            });
        },
        onError: (_, removedFile) => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [removedFile];
                return [...cachedData, removedFile];
            });
        },
    });
    return {
        removeFile: async (file, onSuccess, onError) => {
            await removeFileMutation.mutateAsync(file, {
                onSuccess,
                onError: (apiError) => {
                    console.error('File remove error', apiError);
                    onError(apiError.message);
                },
            });
        },
        isRemoving: removeFileMutation.isLoading,
    };
};
