import React from 'react';
export const ListingProfileSubmitErrorsList = ({ submitErrors }) => {
    const submitErrorLabels = Object.keys(submitErrors).map((key) => submitErrors[key].params?.label);
    return (React.createElement("div", null,
        "Please check validation errors ",
        submitErrorLabels.length ? 'and fill in all required fields :' : '',
        submitErrorLabels.map((label) => {
            return (React.createElement("div", { key: label, className: "mt-1" }, label));
        })));
};
