import { axiosAuthorizedInstance, dataFromResponse, getApiBasePath } from '~services';
export async function getAll(listingId) {
    const url = `${getApiBasePath()}/files/${listingId}`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
export async function upload(data, listingId) {
    const url = `${getApiBasePath()}/files/${listingId}`;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('fileName', data.file.name);
    if (data.docTypeId) {
        formData.append('docTypeId', data.docTypeId);
    }
    if (data.docTypeOther) {
        formData.append('docTypeOther', data.docTypeOther);
    }
    const config = {
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'content-type': 'multipart/form-data',
        },
    };
    return dataFromResponse(axiosAuthorizedInstance.put(url, formData, config));
}
export async function remove(fileId) {
    const url = `${getApiBasePath()}/files/${fileId}`;
    return axiosAuthorizedInstance.delete(url);
}
export async function getDocTypes() {
    const url = `${getApiBasePath()}/files/doc-types`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
