/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Divider, Flex, Stack } from '@chakra-ui/react';
import { Button, ButtonBlock, Heading, snackbar, Spinner, Text } from '~components';
import { useFiles, useFormValidationErrors, useListing, useRouting, useSubmitListing, useUpdateListing } from '~hooks';
import { getListingStatusName, ListingDetails, ListingDetailsForm, TListingPageMode, toListingProfileFormModel, useListingDetailsForm, } from '~pages';
import { ListingProfileAccept, ListingProfileLeavePageAction, ListingProfileReject, ListingProfileSave, ListingProfileSubmit, } from '~pages/Listings/ListingProfilePage/ListingProfileFormActions';
import { THasHoa, TOfferStatus } from '~types';
import { ListingFiles } from './listing-files';
import { listingProfileRequiredSubmitSchema } from './listing-profile.validation';
export const ListingProfilePage = () => {
    const { search, toSearchString, navigate } = useRouting();
    const [listing, isListingLoading, { isError, error }] = useListing();
    const [files, isFilesLoading] = useFiles();
    const updateListingMutation = useUpdateListing();
    const submitListingMutation = useSubmitListing();
    const [submitErrors, setSubmitErrors] = useState({});
    const [initialFilesList, setInitialFilesList] = useState();
    const isStatusRejected = listing?.offer?.status === TOfferStatus.REJECTED;
    const isComponentLoading = isListingLoading || updateListingMutation.isLoading || submitListingMutation.isLoading;
    const isStatusSubmitted = useMemo(() => listing?.offer?.status === TOfferStatus.SUBMITTED, [listing?.offer?.status]);
    const isFormDisabled = listing.pageMode === TListingPageMode.FORM_DISABLED;
    const submitRequiredErrorResolver = useFormValidationErrors(listingProfileRequiredSubmitSchema);
    const listingDetailsForm = useListingDetailsForm(isFormDisabled);
    const { getValues, formState, watch, setValue } = listingDetailsForm;
    const hasHoaValue = watch('offer.hasHOA');
    const resetHoaFields = () => {
        const { offer } = listing;
        setValue('offer.hoaName', offer.hoaName);
        setValue('offer.hoaPhone', offer.hoaPhone);
        setValue('offer.hoaWebsite', offer.hoaWebsite);
        setValue('offer.hoaTransferFee', offer.hoaTransferFee);
        setValue('offer.hoaFee', offer.hoaFee);
        setValue('offer.hoaFrequency', offer.hoaFrequency);
    };
    useEffect(() => {
        if (hasHoaValue === THasHoa.NO)
            resetHoaFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasHoaValue]);
    const isFormChanged = useMemo(() => formState.isDirty ||
        initialFilesList?.length !== files.length ||
        initialFilesList.some((initialFile) => !files.find((file) => initialFile.id === file.id)), [formState, initialFilesList, files]);
    const isFileUploadDisabled = isListingLoading || !formState.isValid || isFormDisabled || isStatusRejected;
    const validateSubmitErrors = async () => {
        const { errors } = await submitRequiredErrorResolver(getValues());
        setSubmitErrors(errors);
    };
    useEffect(() => {
        if (isError)
            snackbar.error(`Cannot fetch listing at the moment`);
    }, [isError]);
    useEffect(() => {
        if (listing?.offer) {
            const formData = toListingProfileFormModel(listing);
            listingDetailsForm.reset(formData);
            void validateSubmitErrors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing]);
    useEffect(() => {
        if (files?.length !== initialFilesList?.length && !initialFilesList && !isFilesLoading) {
            setInitialFilesList(files);
        }
    }, [files, initialFilesList, isFilesLoading]);
    const returnToListingPage = () => {
        const formData = toListingProfileFormModel(listing);
        listingDetailsForm.reset(formData);
        navigate(`/listings?${toSearchString(search)}`);
    };
    if (isError && error) {
        const { message } = error;
        return (React.createElement(Flex, { width: "full", flexDirection: "column" },
            React.createElement(Button, { onClick: returnToListingPage, variant: "link", type: "submit", maxW: "fit-content", alignSelf: "flex-start", p: "10px 15px" }, "Back to search"),
            React.createElement(Heading, { as: "h6", size: "small", textAlign: "center", className: "text-violet-800", p: 5, m: "100px auto" }, message)));
    }
    return (React.createElement(Spinner, { isLoading: isComponentLoading },
        React.createElement(Flex, { width: "full" },
            React.createElement(Stack, { width: "full" },
                React.createElement(FormProvider, { ...listingDetailsForm },
                    React.createElement(Flex, { direction: { base: 'column-reverse', md: 'row' }, alignItems: { base: 'end', md: 'center' }, width: "full", justifyContent: "space-between" },
                        React.createElement(Button, { className: "md:mr-[20px]", variant: "link", onClick: returnToListingPage }, "Back to search"),
                        React.createElement(Flex, { width: "full", flex: "2", flexDirection: "row", className: "md:pr-3", justifyContent: "space-between" },
                            React.createElement(Text, { className: "text-stone-500 font-[550]" }, listing?.details?.address),
                            React.createElement(Text, { className: "text-stone-500 font-[550]" },
                                "Status: ",
                                getListingStatusName(listing?.offer?.status))),
                        React.createElement(Flex, { flex: "1", justifyContent: "flex-end" },
                            React.createElement(ButtonBlock, { direction: "row", width: "fit-content" },
                                React.createElement(ListingProfileSave, { isStatusSubmitted: isStatusSubmitted, isFormChanged: isFormChanged, updateListingMutation: updateListingMutation.mutateAsync }),
                                React.createElement(ListingProfileReject, null),
                                React.createElement(ListingProfileAccept, null),
                                React.createElement(ListingProfileSubmit, { submitErrors: submitErrors, setInitialFilesList: setInitialFilesList, submitListingMutation: submitListingMutation.mutateAsync })))),
                    React.createElement(Divider, { orientation: "horizontal" }),
                    React.createElement(ListingDetails, null),
                    React.createElement(Divider, { orientation: "horizontal" }),
                    React.createElement(ListingDetailsForm, { isFormDisabled: isFormDisabled, onFormChange: validateSubmitErrors, hasHOA: hasHoaValue === THasHoa.YES }),
                    React.createElement(Divider, { orientation: "horizontal" }),
                    React.createElement(ListingFiles, { isDisabled: isFileUploadDisabled }),
                    React.createElement(ListingProfileLeavePageAction, { updateListingMutation: updateListingMutation.mutateAsync }))))));
};
