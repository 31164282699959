import { useQuery, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getQueryMethods, getQueryStatus, useGetCurrentUser, useRouting } from '~hooks';
import { listingsService } from '~services';
export const LISTINGS_KEY = 'listings';
export const getListingsKey = (listingId, address, status, page = 0, brokerageId) => [
    LISTINGS_KEY,
    listingId,
    address,
    status,
    page,
    brokerageId,
];
export function useListings(page) {
    const queryClient = useQueryClient();
    const [{ brokerageId }] = useGetCurrentUser();
    const { search: { listingId, address, statuses }, } = useRouting();
    const KEY = getListingsKey(listingId, address, statuses, page, brokerageId);
    const query = useQuery(KEY, async () => listingsService.search(listingId, address, statuses, page), {
        onError: () => {
            snackbar.error('Cannot fetch listings at the moment');
        },
    });
    const result = query.data && !query.isError ? query.data.items : [];
    const totalPageCount = query.data?.totalPageCount ?? 0;
    const pageNumber = query.data?.pageNumber ?? 0;
    const queryStatus = getQueryStatus(query);
    return [result, queryStatus.isLoading, { totalPageCount, pageNumber, ...getQueryMethods(query, queryClient, KEY), ...queryStatus }];
}
