/* eslint-disable */
// @ts-nocheck
// disabled for pipeline to fix in next ticket
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, ConfirmModal, useModal } from '~components';
import { useAcceptListing, useListing } from '~hooks';
import { TListingPageMode } from '~pages';
export const ListingProfileAccept = () => {
    const [listing, isListingLoading] = useListing();
    const [modalProps, confirm] = useModal();
    const acceptListingMutation = useAcceptListing();
    const { handleSubmit, formState } = useFormContext();
    const isAcceptButtonDisabled = listing.pageMode === TListingPageMode.FORM_DISABLED ||
        listing.pageMode === TListingPageMode.SUBMIT_ENABLED ||
        isListingLoading ||
        !formState.isValid;
    const onFromAccept = async ({ offer }) => {
        if (await confirm()) {
            await acceptListingMutation.mutateAsync({ ...offer });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { disabled: isAcceptButtonDisabled, variant: "solid", onClick: handleSubmit(onFromAccept) }, "ACCEPT"),
        React.createElement(ConfirmModal, { title: "Are you sure you want to accept this offer?", ok: "YES", cancel: "NO", ...modalProps })));
};
