import React from 'react';
import { Heading as ChakraHeading } from '@chakra-ui/react';
export const Heading = (props) => {
    const { children, ...restOfHeadingProps } = props;
    return React.createElement(ChakraHeading, { ...restOfHeadingProps }, children);
};
export const HeadingTheme = {
    baseStyle: {
        lineHeight: 1.1,
        fontWeight: '500',
    },
    sizes: {
        md: {
            fontSize: '4xl',
        },
        sm: {
            fontSize: '3xl',
        },
        base: {
            fontSize: '2xl',
        },
        small: {
            fontSize: 'xl',
        },
    },
};
