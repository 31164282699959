import React from 'react';
import { Input as ChakraInput, theme as baseTheme } from '@chakra-ui/react';
export const Input = (props) => {
    const { size = 'md', variant = 'outline', className, innerRef, placeholder, maxWidth, ...restOfInputProps } = props;
    return (React.createElement(ChakraInput, { "aria-label": placeholder, placeholder: placeholder, ref: innerRef, sx: { backgroundColor: 'var(--color-white)' }, className: `${className} max-w-${maxWidth}`, ...{ size, variant }, ...restOfInputProps }));
};
export const InputTheme = {
    variants: {
        outline: {
            field: {
                borderRadius: 'none',
            },
        },
        rounded: (props) => ({
            ...baseTheme.components.Input.variants.outline(props),
            field: {
                ...baseTheme.components.Input.variants.outline(props).field,
                borderRadius: '12px',
            },
        }),
    },
    defaultProps: {
        focusBorderColor: 'purple.400',
    },
};
