/* eslint-disable @typescript-eslint/naming-convention */
export var TJwtTokenField;
(function (TJwtTokenField) {
    TJwtTokenField["BROKERAGE_NAME"] = "BrokerageName";
    TJwtTokenField["EXPIRATION_TIMESTAMP"] = "exp";
    TJwtTokenField["ROLE"] = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    TJwtTokenField["EMAIL"] = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
    TJwtTokenField["NAME"] = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
    TJwtTokenField["ID"] = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
})(TJwtTokenField || (TJwtTokenField = {}));
