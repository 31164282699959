import { useLocation, useNavigate, useParams } from 'react-router';
export function useRouting() {
    const { userId, brokerageId, listingId } = useParams();
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    return {
        search: {
            brokerageId: getSearchQueryParameter(search, 'brokerageId'),
            listingId: getSearchQueryParameter(search, 'listingId'),
            address: getSearchQueryParameter(search, 'address'),
            name: getSearchQueryParameter(search, 'name'),
            email: getSearchQueryParameter(search, 'email'),
            token: getSearchQueryParameter(search, 'token'),
            statuses: getSearchQueryParameter(search, 'statuses'),
            page: Number.parseInt(getSearchQueryParameter(search, 'page') ?? '0', 10),
        },
        toSearchString,
        userId,
        brokerageId,
        listingId,
        pathname,
        goBack: () => {
            navigate(-1);
        },
        navigate,
    };
}
function getSearchQueryParameter(search, key) {
    const params = search ? new URLSearchParams(search) : undefined;
    if (!params)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return params.get(key) || undefined;
}
function toSearchString(searchKeyValues) {
    if (!searchKeyValues)
        return '';
    return Object.entries(searchKeyValues)
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
}
