import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Modal as ChakraModal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { mode, transparentize } from '@chakra-ui/theme-tools';
import { Button, Heading, Text } from '~components';
import { useStore } from '~hooks';
import { ModalActions } from './ModalActions';
// Chakra modal scrolls to first button which is not desired on a long content
// This workaround creates a fake button at the top and transfers focus to real submit button without scrolling
const ScrollToTopWorkaround = ({ submitButtonRef }) => (React.createElement(Button, { variant: "ghost", className: "flex h-[0] min-w-[0] p-[0] focus:shadow-none", onFocusCapture: () => {
        submitButtonRef.current?.focus({ preventScroll: true });
    } }));
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Modal(props) {
    const { isCentered = true, width, type, title, message, controls, children } = props;
    const { isVisible, reject } = controls;
    const { onClose = reject } = props;
    const { isErrorModalShown } = useStore();
    const submitButtonRef = useRef(null);
    return createPortal(React.createElement(ChakraModal, { colorScheme: getColorScheme(type), isCentered: isCentered, isOpen: !!isVisible && (type === 'error' || !isErrorModalShown), onClose: onClose },
        React.createElement(ModalOverlay, null),
        React.createElement(ModalContent, { className: `${!width ? 'max-w-full sm:max-w-lg md:max-w-xl lg:max-w-4xl' : ''} border-2 border-purple`, maxWidth: width },
            title ? (React.createElement(ModalHeader, { textAlign: "center", className: "mt-[15px]", p: 5 },
                React.createElement(Heading, { className: "text-2xl text-violet-800" }, title),
                React.createElement(ScrollToTopWorkaround, { submitButtonRef: submitButtonRef }))) : null,
            React.createElement(ModalBody, null, message ? React.createElement(Text, { textAlign: "justify" }, message) : children),
            React.createElement(ModalFooter, { className: "flex flex-col" },
                React.createElement(ModalActions, { ...props })))), document.body);
}
// export const Modal = modal;
function getColorScheme(type) {
    switch (type) {
        case 'error':
            return 'red';
        case 'warn':
            return 'cyan';
        default:
            return 'grey';
    }
}
const baseStyle = {
    px: 1,
    fontSize: 'md',
};
function variantSolid(props) {
    const { colorScheme: c, theme } = props;
    // eslint-disable-next-line deprecation/deprecation, @typescript-eslint/no-unsafe-argument
    const dark = transparentize(`${c}.500`, 0.6)(theme);
    return {
        dialog: {
            margin: 2,
            padding: 0,
            borderRadius: 5,
            borderWidth: 2,
            borderColor: mode(`${c}.600`, dark)(props),
            bg: mode(`white`, dark)(props),
        },
        body: {
            px: 6,
            py: 4,
            bg: mode(`white`, dark)(props),
            color: mode(`color-body`, `color-body-invert`)(props),
        },
        header: {
            px: 6,
            py: 4,
            color: mode(`color-body-invert`, `color-body`)(props),
        },
    };
}
const variants = {
    solid: variantSolid,
};
const defaultProps = {
    variant: 'solid',
    colorScheme: 'gray',
};
export const ModalTheme = {
    parts: ['dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
    baseStyle,
    variants,
    defaultProps,
};
