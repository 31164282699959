import { useMutation } from '@tanstack/react-query';
import { adminServices } from '~services';
export const useChangeUserBrokerage = () => {
    const changeUserBrokerage = async (adminBrokerageId) => {
        await adminServices.users.changeBrokerage(adminBrokerageId);
    };
    const updateBrokerageMutation = useMutation(changeUserBrokerage);
    return {
        changeUserBrokerage: async (adminBrokerageId) => updateBrokerageMutation.mutateAsync(adminBrokerageId),
        isUpdating: updateBrokerageMutation.isLoading,
    };
};
