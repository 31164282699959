import React from 'react';
import { useAuth, useBrokerages, useGetCurrentUser } from '~hooks';
import { ListingsErrorPage } from '~pages';
import { TUserRole } from '~types';
export const SalesforceRoute = ({ element }) => {
    const { role } = useAuth();
    const [currentUser] = useGetCurrentUser();
    const { brokerageId } = currentUser;
    const [brokerages, isLoading] = useBrokerages();
    if (role !== TUserRole.ADMIN)
        return element;
    if (isLoading)
        return null;
    if (brokerageId && !!brokerages.find((brokerage) => brokerage.id === brokerageId)) {
        return element;
    }
    return React.createElement(ListingsErrorPage, null);
};
