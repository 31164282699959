import jwtDecode from 'jwt-decode';
import { TJwtTokenField, TUserRole } from '~types';
export function userFromJwtToken(token) {
    const decodedToken = jwtDecode(token);
    return {
        name: decodedToken[TJwtTokenField.NAME],
        email: decodedToken[TJwtTokenField.EMAIL],
        role: decodedToken[TJwtTokenField.ROLE]?.toLowerCase() || TUserRole.UNDEFINED,
        brokerageName: decodedToken[TJwtTokenField.BROKERAGE_NAME],
        token,
    };
}
