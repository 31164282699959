export function getSearchParams(params) {
    if (!params)
        return;
    const filteredParams = Object.entries(params)
        .filter(([, value]) => value !== null && value !== undefined)
        .map(([key, value]) => {
        const stringValue = value?.toString() ?? '';
        if (!stringValue.includes(','))
            return [[key, stringValue]];
        return stringValue.split(',').map((valueItem) => {
            return [key, valueItem];
        });
    })
        .flatMap((group) => group);
    return new URLSearchParams(filteredParams).toString();
}
