/* eslint-disable */
// @ts-nocheck
import React, { Component } from 'react';
import flatpickr from 'flatpickr';
import { Input } from '~components';
import './style.less';
const hooks = ['onChange', 'onOpen', 'onClose', 'onMonthChange', 'onYearChange', 'onReady', 'onValueUpdate', 'onDayCreate'];
const callbacks = ['onCreate', 'onDestroy'];
export class DateTimePicker extends Component {
    componentDidMount() {
        this.createFlatpickrInstance();
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.value) === JSON.stringify(prevProps.value))
            return;
        const { options } = this.props;
        const prevOptions = prevProps.options;
        hooks.forEach((hook) => {
            if (this.props.hasOwnProperty(hook)) {
                options[hook] = this.props[hook];
            }
            if (prevProps.hasOwnProperty(hook)) {
                prevOptions[hook] = prevProps[hook];
            }
        });
        const optionsKeys = Object.getOwnPropertyNames(options);
        for (let index = optionsKeys.length - 1; index >= 0; index--) {
            const key = optionsKeys[index];
            let value = options[key];
            if (value !== prevOptions[key]) {
                // Hook handlers must be set as an array
                if (hooks.includes(key) && !Array.isArray(value)) {
                    value = [value];
                }
                this.flatpickr.set(key, value);
            }
        }
        if (this.props.hasOwnProperty('month') && this.props.month !== prevProps.month) {
            this.flatpickr.changeMonth(this.props.month, false);
        }
        if (this.props.hasOwnProperty('value') && this.props.value !== prevProps.value) {
            this.flatpickr.setDate(this.props.value, false);
        }
    }
    componentWillUnmount() {
        this.destroyFlatpickrInstance();
    }
    createFlatpickrInstance = () => {
        const options = {
            onClose: () => {
                if (this.node.blur)
                    this.node.blur();
            },
            ...this.props.options,
        };
        // Add prop hooks to options
        hooks.forEach((hook) => {
            if (this.props[hook]) {
                options[hook] = this.props[hook];
            }
        });
        this.flatpickr = flatpickr(this.node, options);
        if (this.props.hasOwnProperty('value')) {
            this.flatpickr.setDate(this.props.value, false);
        }
        const { onCreate } = this.props;
        if (onCreate)
            onCreate(this.flatpickr);
    };
    destroyFlatpickrInstance = () => {
        const { onDestroy } = this.props;
        if (onDestroy)
            onDestroy(this.flatpickr);
        this.flatpickr.destroy();
        this.flatpickr = null;
    };
    handleNodeChange = (node) => {
        this.node = node;
        if (this.flatpickr) {
            this.destroyFlatpickrInstance();
            this.createFlatpickrInstance();
        }
    };
    render() {
        // eslint-disable-next-line no-unused-vars
        const { options, defaultValue, value, children, render, ...props } = this.props;
        hooks.forEach((hook) => {
            delete props[hook];
        });
        callbacks.forEach((callback) => {
            delete props[callback];
        });
        if (render)
            return render({ ...props, defaultValue, value }, this.handleNodeChange);
        return options.wrap ? (React.createElement("div", { ...props, ref: this.handleNodeChange }, children)) : (React.createElement(Input, { ...props, defaultValue: defaultValue, innerRef: this.handleNodeChange }));
    }
}
