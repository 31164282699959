import React from 'react';
import { Select as ChakraReactSelect } from 'chakra-react-select';
import { enumToEntries } from '~utils';
export const MultiSelect = (props) => {
    const { size = 'md', variant = 'outline', className, isLoading, ...restOfSelectProps } = props;
    return (React.createElement(ChakraReactSelect, { isMulti: true, useBasicStyles: true, isLoading: isLoading, focusBorderColor: "purple.400", className: `rounded-none ${className}`, isSearchable: false, ...{ size, variant }, ...restOfSelectProps }));
};
export function enumToSelectOptions(valuesEnum, labelsEnum) {
    const valuesEnumEntries = enumToEntries(valuesEnum);
    const labelsEnumEntries = enumToEntries(labelsEnum);
    if (valuesEnumEntries.length !== labelsEnumEntries.length)
        throw new Error('labelsEnum must contain the same amount of keys as a valuesEnum');
    const labelsMap = new Map(labelsEnumEntries);
    return valuesEnumEntries.map(([key, value]) => ({
        label: labelsMap.get(key) ?? 'NO LABEL',
        value,
    }));
}
