/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useRef, useState } from 'react';
import { ImCalendar } from 'react-icons/im';
import { IoCaretBack, IoCaretForward } from 'react-icons/io5';
import { InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { format, isDate, parse, setMonth as setMonthToDate, setYear as setYearToDate } from 'date-fns';
import { Button, DateTimePicker, FormField, Input, NumberInput } from '~components';
import { TDateFormat } from '~types';
export const DateTimePickerField = (props) => {
    const { size = 'md', name, label, maxWidth, width = '150px', labelPosition, helperText, isRequired, enabledDates = [], disabled, ...restOfProps } = props;
    const containerRef = useRef();
    const [isHidden, setIsHidden] = useState(true);
    const eventHandler = (event) => {
        if (!containerRef.current?.contains(event.target))
            setIsHidden(true);
    };
    useEffect(() => {
        document.addEventListener('click', eventHandler);
        return () => {
            document.removeEventListener('click', eventHandler);
        };
    }, []);
    return (React.createElement(FormField, { className: "flex flex-row items-center w-auto sm:items-start", ...{ name, label, labelPosition, helperText, isRequired, maxWidth, width }, validationRules: { isRequired }, 
        // eslint-disable-next-line no-unused-vars
        render: ({ onChange, onBlur, value, error }) => {
            const year = (value && new Date(value).getFullYear()) || 0;
            const monthValue = (value && new Date(value).getMonth()) || 0;
            const monthLabel = format(new Date(new Date().getFullYear(), monthValue, 1), 'MMMM');
            const isPrevMonthDisabled = !!enabledDates.length && !enabledDates.find((date) => date.getMonth() === monthValue - 1);
            const isNextMonthDisabled = !!enabledDates.length && !enabledDates.find((date) => date.getMonth() === monthValue + 1);
            return (React.createElement(InputGroup, { ref: containerRef, flexDirection: "column", width: width, size: size, maxWidth: maxWidth ?? width },
                React.createElement(Input, { disabled: disabled, onClick: () => {
                        setIsHidden(false);
                    }, className: "pr-10 max-w-[164px]", isReadOnly: true, value: isDate(value) ? format(value, TDateFormat.DATE_ONLY) : '' }),
                React.createElement(InputRightElement, { pointerEvents: "none" },
                    React.createElement(ImCalendar, { style: { color: 'var(--color-purple-500)' } })),
                React.createElement(Stack, { className: `absolute z-40 right-0 bg-slate-50 ${isHidden ? 'hidden' : 'flex'} ${error ? 'invalid' : ''}`, pb: 1, border: `1px ${error ? 'var(--color-red-600)' : 'var(--color-gray-200)'} solid!important` },
                    React.createElement(Stack, { direction: "row", className: "items-center justify-between my-1" },
                        React.createElement(Button, { variant: "ghost", onClick: () => {
                                const monthIdx = monthValue - 1 >= 0 ? (monthValue - 1) % 12 : 11;
                                if (monthIdx === 11) {
                                    const updatedValueByMonth = setMonthToDate(value, monthIdx);
                                    onChange(setYearToDate(updatedValueByMonth, year - 1));
                                }
                                else {
                                    onChange(setMonthToDate(value, monthIdx));
                                }
                            }, isDisabled: isPrevMonthDisabled },
                            React.createElement(IoCaretBack, null)),
                        React.createElement("p", null, monthLabel),
                        React.createElement(NumberInput, { isInvalid: false, maxWidth: "100px", value: year, onChange: (_, yearValue) => {
                                if (!isDate(value)) {
                                    console.warn(`DatePicker value ${value} is not a date`);
                                    return;
                                }
                                onChange(setYearToDate(value, yearValue));
                            } }),
                        React.createElement(Button, { variant: "ghost", onClick: () => {
                                const monthIdx = (monthValue + 1) % 12;
                                if (monthIdx === 0) {
                                    const updatedValueByMonth = setMonthToDate(value, monthIdx);
                                    onChange(setYearToDate(updatedValueByMonth, year + 1));
                                }
                                else {
                                    onChange(setMonthToDate(value, monthIdx));
                                }
                            }, isDisabled: isNextMonthDisabled },
                            React.createElement(IoCaretForward, null))),
                    React.createElement(DateTimePicker, { className: "input-hidden", 
                        // @ts-expect-error: Property option does not exists
                        options: {
                            inline: true,
                            ...(enabledDates.length && { enable: enabledDates }),
                            mode: 'single',
                            allowInvalidPreload: true,
                        }, onChange: (_, data) => {
                            onChange(parseFormDate(data));
                            if (!error)
                                setIsHidden(true);
                        }, onBlur: onBlur, month: monthValue, value: value, ...restOfProps }),
                    error ? React.createElement("div", { className: "p-2 mt-0 -mb-1 text-white bg-red-600" }, error.message) : null)));
        } }));
};
function parseFormDate(date) {
    if (!date)
        return;
    try {
        return parse(date, TDateFormat.FORM, new Date());
        // eslint-disable-next-line no-empty
    }
    catch (e) { }
}
