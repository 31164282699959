import { format } from 'date-fns';
import { THasHoa } from '~types';
export const useListingModel = () => {
    const formatToDateOnly = (dateTime) => `${format(dateTime, 'yyyy-MM-dd')}T00:00:00`;
    const toRequestObject = (offer) => {
        return {
            id: offer.id,
            listingId: offer.listingId,
            initialOfferDate: formatToDateOnly(offer.initialOfferDate),
            finalPurchasePrice: offer.finalPurchasePrice,
            offerBindingDate: formatToDateOnly(offer.offerBindingDate),
            emdDeadline: formatToDateOnly(offer.emdDeadline),
            listingAgentName: offer.listingAgentName,
            listingAgentEmail: offer.listingAgentEmail,
            listingAgentPhone: offer.listingAgentPhone,
            sellerName: offer.sellerName,
            earnestDepositAmount: offer.earnestDepositAmount,
            closeOfEscrowDate: formatToDateOnly(offer.closeOfEscrowDate),
            dueDiligenceStartDate: formatToDateOnly(offer.dueDiligenceStartDate),
            dueDiligenceExpirationDate: formatToDateOnly(offer.dueDiligenceExpirationDate),
            commissionTotal: offer.commissionTotal,
            wellWaterProvider: offer.wellWaterProvider,
            electricProvider: offer.electricProvider,
            sewerSepticProvider: offer.sewerSepticProvider,
            gasProvider: offer.gasProvider,
            lockBox: offer.lockBox,
            accessMethod: offer.accessMethod,
            status: offer.status,
            occupancy: offer.occupancy,
            gateCode: offer.gateCode,
            hasHOA: offer.hasHOA === THasHoa.YES,
            hoaName: offer.hoaName,
            hoaWebsite: offer.hoaWebsite,
            hoaPhone: offer.hoaPhone,
            hoaTransferFee: offer.hoaTransferFee,
            hoaFee: offer.hoaFee,
            hoaFrequency: offer.hoaFrequency,
        };
    };
    return {
        toRequestObject,
    };
};
