import React from 'react';
import { Spinner as ChakraSpinner } from '@chakra-ui/react';
const fullScreen = (isFullScreen) => (isFullScreen ? 'absolute top-1/2 left-1/2 min-v-screen' : '');
const overlay = (isVisible) => isVisible ? 'relative clear-both overflow-hidden opacity-25 select-none pointer-events-none' : '';
export const Spinner = ({ isLoading, isFullScreen, isOverlayShown = true, className, children }) => {
    return (React.createElement("div", { className: `${className ?? ''} flex grow relative w-full` },
        React.createElement("div", { className: `flex grow transition-opacity duration-300 ${isOverlayShown && overlay(isLoading)}` }, children),
        isLoading ? (React.createElement("div", { className: `transition-opacity duration-100 ${fullScreen(isFullScreen)} inline-block` },
            React.createElement("div", { className: "absolute opacity-50 top-1/2 left-1/2 translate-center" },
                React.createElement(ChakraSpinner
                // eslint-disable-next-line @typescript-eslint/naming-convention
                , { 
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    sx: { '--spinner-size': '4em;', borderWidth: '5px' }, className: "inline-block duration-700 animate-spin" })))) : null));
};
