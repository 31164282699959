import React, { useRef } from 'react';
import { Button, ButtonBlock } from '~components';
import { useLayout } from '~hooks';
import { getKey } from '~utils';
const ModalActionButton = ({ action, ...props }) => {
    const { isHidden, type, ...restOfActionProps } = action;
    const { submitButtonRef, submit, reject, ...restOfProps } = props;
    if (isHidden)
        return null;
    return (React.createElement(Button, { ...restOfActionProps, ...restOfProps, minWidth: "100px", minHeight: "40px", onClick: getActionHandler(action, submit, reject), type: type === 'submit' ? 'submit' : 'button', ref: type === 'submit' ? submitButtonRef : undefined }, getActionTitle(action)));
};
// eslint-disable-next-line react/function-component-definition, @typescript-eslint/naming-convention
export function ModalActions(props) {
    const { actions: initialActions, controls } = props;
    const { reject, submit } = controls;
    const { onClose = reject } = props;
    const { isMobile } = useLayout();
    const submitButtonRef = useRef(null);
    let actions = initialActions ?? [];
    if (!isMobile) {
        actions = initialActions?.sort((a) => (a.align === 'left' ? -1 : 1)) ?? [];
    }
    return (React.createElement(ButtonBlock, { className: "flex-wrap", justify: "center", direction: "row" }, actions.length ? (actions.map((action, index) => (React.createElement(ModalActionButton, { key: getKey(action, index), variant: action.variant, ...{ action, submitButtonRef, submit, reject, onClose } })))) : (React.createElement(Button, { type: "submit", onClick: onClose, full: isMobile, ref: submitButtonRef }, "Close"))));
}
function getActionHandler(action, submit, reject) {
    if (action.onClick)
        return action.onClick;
    if (action.type === 'submit')
        return () => {
            submit(true);
        };
    if (action.type === 'reject')
        return reject;
}
function getActionTitle(action) {
    if (action.title)
        return action.title;
    if (action.type === 'submit')
        return 'Ok';
    if (action.type === 'reject')
        return 'Cancel';
}
