/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FormField, Input } from '~components';
export const EmailField = (props) => {
    const { name, type, size = 'md', label, labelPosition, helperText, isRequired, ...restOfProps } = props;
    return (React.createElement(FormField, { ...{ name, label, labelPosition, helperText, isRequired }, validationRules: getValidationRules(isRequired), render: ({ ref, error, ...controllerProps }) => (React.createElement(InputGroup, { size: size },
            React.createElement(InputLeftElement, { pointerEvents: "none" },
                React.createElement(AiOutlineMail, null)),
            React.createElement(Input, { className: "pl-10", type: "email", autoComplete: "email", placeholder: label, ...controllerProps, ...restOfProps }))) }));
};
function getValidationRules(isRequired) {
    const requiredRule = isRequired ? { required: 'Please enter email' } : {};
    return {
        ...requiredRule,
        validate: {
            isValidEmail: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim()) || 'Please enter valid email',
        },
    };
}
