/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex, Switch, Tooltip } from '@chakra-ui/react';
import { buildTableColumns, Button, DataTable, enumToSelectOptions, Form, Paginator } from '~components';
import { useGetCurrentUser, useListings, useRouting } from '~hooks';
import { TDateFormat, TOfferStatus, TOfferStatusLabels } from '~types';
import { tryParseAndFormat } from '~utils';
const statusesOptions = enumToSelectOptions(TOfferStatus, TOfferStatusLabels);
export const ListingsListPage = () => {
    const { search, toSearchString, navigate } = useRouting();
    const [listings, isLoading, { totalPageCount, pageNumber, refetch }] = useListings(search.page);
    const [, isGetCurrentUserLoading] = useGetCurrentUser();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formMethods = useForm();
    const { setValue, watch, getValues } = formMethods;
    const statusesVal = watch('statuses') || [];
    // @ts-expect-error
    const isSearchForNotSubmittedSwitched = statusesVal.length === 5 && !statusesVal.includes(TOfferStatus.SUBMITTED);
    useEffect(() => {
        void refetch();
        formMethods.reset({
            listingId: search.listingId,
            address: search.address,
            statuses: search.statuses?.split(',') ?? [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const saveListingIdInSessionStorage = (listingId) => {
        sessionStorage.setItem('activeListingId', `${listingId}`);
    };
    const columns = useMemo(() => buildTableColumns([
        ['listingId', 'ID'],
        [(listing) => getStatusName(listing.status), 'Status'],
        ['address', 'Address'],
        ['market', 'Market'],
        [(listing) => tryParseAndFormat(listing.scheduledClose, TDateFormat.DATE_ONLY), 'Closing Date'],
        [(listing) => tryParseAndFormat(listing.offerAccepted, TDateFormat.DATE_ONLY), 'Offer Accepted'],
        [(listing) => tryParseAndFormat(listing.ddExpiration, TDateFormat.DATE_ONLY), 'DD Expiration'],
        [(listing) => `$ ${Math.round(listing.contractPrice)}`, 'Contract Price'],
        [
            (listing) => (React.createElement(Button, { disabled: isLoading || isGetCurrentUserLoading, onClick: () => {
                    saveListingIdInSessionStorage(listing.listingId);
                }, variant: "link", to: `/listing/${listing.listingId}?${toSearchString(search)}` }, listing.hasBid ? 'View' : 'Edit')),
            '',
        ],
    ]), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]);
    const onSubmit = (form) => {
        const statuses = typeof form.statuses !== 'string' ? form.statuses?.join(',') : '';
        if (isSubmitting || (search.address === form.address && search.listingId === form.listingId && search.statuses === statuses))
            return;
        try {
            setIsSubmitting(true);
            navigate(`/listings?${toSearchString({
                listingId: form.listingId,
                address: form.address,
                statuses,
                page: 0,
            })}`);
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const handleNotSubmittedPress = () => {
        if (!isSearchForNotSubmittedSwitched) {
            const notSubmittedStatuses = [
                TOfferStatus.REJECTED,
                TOfferStatus.PENDING,
                TOfferStatus.INCOMPLETE,
                TOfferStatus.NOT_STARTED,
                TOfferStatus.READY_FOR_ESCROW_INFO,
            ];
            // @ts-expect-error
            setValue('statuses', notSubmittedStatuses);
        }
        else {
            setValue('statuses', []);
        }
        onSubmit(getValues());
    };
    const navigateToPage = (page) => {
        navigate(`/listings?${toSearchString({
            ...search,
            page,
        })}`);
    };
    return (React.createElement(Flex, { width: "full", flexDirection: "column" },
        React.createElement(FormProvider, { ...formMethods },
            React.createElement(Flex, { as: "form", onSubmit: formMethods.handleSubmit(onSubmit), className: "flex-row flex-wrap w-full pb-4 gap-x-4" },
                React.createElement(Flex, { className: "flex-row gap-4", flexGrow: "4" },
                    React.createElement(Form.Text, { name: "listingId", label: "Listing ID", labelPosition: "top", width: "150px", "data-testid": "listing-id-listings-form" }),
                    React.createElement(Form.Text, { name: "address", label: "Address", labelPosition: "top", minWidth: "100px", width: "100%", fieldWidth: "100%", "data-testid": "address-listings-form" })),
                React.createElement(Flex, { className: "flex-row items-end justify-between gap-4", flexGrow: "2" },
                    React.createElement(Form.MultiSelect, { minWidth: "150px", name: "statuses", label: "Status", labelPosition: "top", options: statusesOptions, fieldWidth: "100%", placeholder: "Any", "data-testid": "statuses-listings-form" }),
                    React.createElement(Tooltip, { size: "lg", hasArrow: true, label: isSearchForNotSubmittedSwitched ? 'Show All Listings' : 'Show Not Submitted', shouldWrapChildren: true, placement: "top", className: "p-2 text-base" },
                        React.createElement(Switch, { className: "pb-2", size: "lg", disabled: isSubmitting, isChecked: isSearchForNotSubmittedSwitched, onChange: handleNotSubmittedPress, "data-testid": "submitted-status-switch-listings-form" })),
                    React.createElement(Button, { type: "submit", isLoading: isSubmitting, className: "min-w-[100px]", "data-testid": "search-listings-submit-button" }, "Search")))),
        React.createElement(DataTable, { columns: columns, data: listings, isLoading: isLoading || isGetCurrentUserLoading, isRowHighlightedFn: isListingTableRowHighlighted, noDataMessage: "No listings were found that match your search criteria", "data-testid": "listings-table" }),
        React.createElement(Paginator, { ...{ totalPageCount, pageNumber }, onChange: navigateToPage, stickToBottom: true })));
};
function isListingTableRowHighlighted(row) {
    return row.listingId.toString() === sessionStorage.getItem('activeListingId');
}
function getStatusName(status) {
    switch (status) {
        case TOfferStatus.NOT_STARTED:
            return 'Not Started';
        case TOfferStatus.PENDING:
            return 'Pending';
        case TOfferStatus.INCOMPLETE:
            return 'Incomplete';
        case TOfferStatus.SUBMITTED:
            return 'Submitted';
        case TOfferStatus.REJECTED:
            return 'Rejected';
        case TOfferStatus.READY_FOR_ESCROW_INFO:
            return 'Ready For Escrow Info';
        default:
            return 'N/A';
    }
}
