export function getQueryStatus(query) {
    const isLoading = query.isLoading || query.isFetching;
    const { isError, isFetching, error } = query;
    const isLoaded = query.isError || query.isSuccess;
    return { isLoading, isLoaded, isError, isFetching, error };
}
export function getQueryMethods(query, queryClient, key) {
    const { remove } = query;
    const setData = (callback) => queryClient.setQueryData(key, callback);
    const invalidate = async () => queryClient.invalidateQueries(key);
    const refetch = async () => query.refetch();
    return { refetch, setData, invalidate, remove };
}
export async function getCancellable(callback, source) {
    const promise = callback();
    promise.cancel = () => {
        source?.cancel('Query was cancelled by React Query');
    };
    return promise;
}
