/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineSecurityScan, AiTwotoneEye } from 'react-icons/ai';
import { InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { FormField, Input } from '~components';
export const PasswordField = (props) => {
    const { name, type, size = 'md', label, labelPosition, helperText, isRequired, isSecure, validationRules, autoComplete = 'current-password', ...restOfProps } = props;
    const [isShown, setIsShown] = useState(false);
    const handleClick = () => {
        setIsShown(!isShown);
    };
    return (React.createElement(FormField, { ...{ name, label, labelPosition, helperText, isRequired }, validationRules: getValidationRules(validationRules, isRequired, isSecure), render: ({ ref, error, ...controllerProps }) => (React.createElement(InputGroup, { size: size },
            React.createElement(InputLeftElement, { pointerEvents: "none" },
                React.createElement(AiOutlineSecurityScan, { className: error ? 'text-error' : '' })),
            React.createElement(Input, { className: "pl-10", autoComplete: autoComplete, type: isShown ? 'text' : 'password', placeholder: label, ...controllerProps, ...restOfProps }),
            React.createElement(InputRightElement, { onClick: handleClick },
                !isShown && React.createElement(AiOutlineEyeInvisible, null),
                isShown ? React.createElement(AiTwotoneEye, null) : null))) }));
};
function getValidationRules(validationRules, isRequired, isSecure) {
    const requiredRule = isRequired ? { required: 'Please enter password' } : {};
    const securityRule = isSecure
        ? {
            validate: {
                hasEightChars: (value) => (value && value.length >= 8) || 'Password should be at least 8 characters long',
                hasNumbers: (value) => /\d/.test(value) || 'Password should contain at least one number',
                hasLowerCase: (value) => /.*[a-z].*/.test(value) || 'Password should contain at least one lowercase character',
                hasUpperCase: (value) => /.*[A-Z].*/.test(value) || 'Password should contain at least one uppercase character',
                hasSpecial: (value) => /.*[`~!@#$%^&*_+=.,:;?(){}|/"<>'\\\\-]|[[]|].*/.test(value) ||
                    'Password should contain at least one special character',
            },
        }
        : {};
    return {
        ...requiredRule,
        ...securityRule,
        ...(validationRules || {}),
    };
}
