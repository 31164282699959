import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Stack } from '@chakra-ui/react';
import { ConfirmModal, snackbar, useModal } from '~components';
import { useDocTypes } from '~hooks';
import { getKey } from '~utils';
import { FileNameForm } from './file-name-form';
import { FileThumbnail } from './file-thumbnail';
export const FileUpload = (props) => {
    const { files, accept, isDisabled, isLoading, getFileValidationError, onUpload, onRemove } = props;
    const [modalProps, confirm, reject] = useModal();
    const [fileAccepted, setFileAccepted] = useState();
    const [form, setForm] = useState({
        docTypeId: '',
        docTypeOther: '',
    });
    const [docTypes] = useDocTypes();
    const [otherItem] = useMemo(() => docTypes.slice(-1), [docTypes]);
    const dropzoneAccept = useMemo(() => Object.fromEntries(accept.split(',').map((type) => [type, []])), [accept]);
    const validate = async (file) => {
        if (!file || isLoading)
            return;
        const validationError = getFileValidationError(file);
        if (validationError) {
            snackbar.error(validationError);
            return;
        }
        setFileAccepted(file);
        await confirm();
    };
    const upload = useCallback(async () => {
        const { docTypeId, docTypeOther } = form;
        if (!docTypeId || docTypeId === 'empty') {
            snackbar.error('Document Type can not be empty');
            return;
        }
        if (otherItem.id.toString() === docTypeId && !docTypeOther) {
            snackbar.error('Other Document Type Name can not be empty');
            return;
        }
        if (!fileAccepted) {
            return;
        }
        reject();
        await onUpload({ file: fileAccepted, docTypeId, docTypeOther }, () => {
            snackbar.success('File successfully uploaded');
        }, () => {
            snackbar.error('Failed to upload file');
        });
    }, [form, fileAccepted, reject, onUpload, otherItem]);
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        disabled: isDisabled,
        accept: dropzoneAccept,
        onDropAccepted: (filesAccepted) => {
            void validate(filesAccepted[0]);
        },
    });
    const remove = async (removedFile) => {
        if (isLoading)
            return;
        await onRemove(removedFile, () => { }, () => {
            snackbar.error('Failed to remove file');
        });
    };
    const modalActions = useMemo(() => [
        {
            title: 'Cancel',
            variant: 'outline',
            type: 'reject',
            onClick: reject,
        },
        {
            title: 'Upload',
            variant: 'solid',
            onClick: upload,
            isDisabled: !form.docTypeId || form.docTypeId === 'empty',
        },
    ], [upload, reject, form]);
    return (React.createElement("div", { ...getRootProps({
            className: `${isDisabled ? 'border-gray cursor-not-allowed' : 'border-purple cursor-pointer'} min-h-[100px] flex flex-col border-2 border-dashed p-2`,
        }) },
        React.createElement("input", { ...getInputProps() }),
        React.createElement(Stack, { direction: "row", align: "baseline", flexWrap: "wrap" }, files.map((file, index) => (React.createElement(FileThumbnail, { ...file, isReadonly: isDisabled, onRemove: () => {
                void remove(file);
            }, key: getKey(file, index) })))),
        React.createElement(ConfirmModal, { ...modalProps, actions: modalActions, width: 500 },
            React.createElement(FileNameForm, { onChange: (data) => {
                    setForm(data);
                } }))));
};
