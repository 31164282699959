import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Button as ChakraButton, theme as baseTheme } from '@chakra-ui/react';
export const Button = forwardRef((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
    const { size, full, to, alignLeft, variant = 'solid', onClick, className, icon, children, ...restOfButtonProps } = props;
    const ariaLabel = typeof props.children === 'string' ? props.children : 'submit';
    const getStyle = () => {
        switch (variant) {
            case 'solid':
                return `${getWidthStyle(props)} ${getLeftAlignStyle(props)} p-4`;
            case 'link':
                return `${getWidthStyle(props)} ${getLeftAlignStyle(props)} p-0`;
            case 'outline':
                return `${getWidthStyle(props)} ${getLeftAlignStyle(props)} p-4 border-2`;
            default:
                return `${getWidthStyle(props)} ${getLeftAlignStyle(props)}`;
        }
    };
    const defaultClassName = 'flex focus:shadow-none m-0';
    return (React.createElement(ChakraButton, { as: to ? Link : undefined, to: to, onClick: onClick, "aria-label": ariaLabel, className: className ?? `${defaultClassName} ${getStyle()}`, variant: variant, colorScheme: "purple", ref: ref, ...restOfButtonProps },
        icon ? React.createElement("span", { className: "mr-1" }, icon) : null,
        children));
});
function getWidthStyle(props) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { full } = props;
    if (full)
        return 'w-full';
}
function getLeftAlignStyle(props) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { alignLeft } = props;
    if (alignLeft)
        return 'mr-auto';
}
export const ButtonTheme = {
    variants: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'solid-rounded': (props) => ({
            ...baseTheme.components.Button.variants.solid(props),
            borderRadius: '12px',
        }),
    },
    baseStyle: {
        fontWeight: '600',
        borderRadius: 'none',
    },
};
