/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Checkbox, Flex } from '@chakra-ui/react';
import { buildTableColumns, Button, DataTable, Form, PageHeader, Paginator } from '~components';
import { useBrokerages, useRouting, useUsers } from '~hooks';
import { getKey, tryParseAndFormat } from '~utils';
export const UsersListPage = () => {
    const { search, toSearchString, navigate } = useRouting();
    const [users, isLoading, { totalPageCount, pageNumber }] = useUsers(search.page);
    const [brokerages, isBrokeragesLoading] = useBrokerages();
    const brokerageNamesSet = useMemo(() => new Map(brokerages.map((b) => [b.id, b.name])), [brokerages]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formMethods = useForm();
    useEffect(() => {
        formMethods.reset(search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columns = useMemo(() => buildTableColumns([
        ['name', 'Name'],
        [(user) => brokerageNamesSet.get(user.brokerageId), 'Company'],
        ['email', 'Email Address'],
        ['phone', 'Telephone'],
        [(brokerage) => tryParseAndFormat(brokerage.lastModifiedDate), 'Last Modified'],
        [(user) => React.createElement(Checkbox, { colorScheme: "purple", isChecked: user.isEnabled }), 'Enabled'],
        [
            'userId',
            {
                // eslint-disable-next-line react/no-unstable-nested-components
                cell: (cell) => (React.createElement(Button, { variant: "link", to: `/user/${cell.getValue()}?${toSearchString(search)}` }, "Edit")),
            },
        ],
    ]), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brokerageNamesSet, search]);
    const onSubmit = (form) => {
        if (isSubmitting)
            return;
        try {
            setIsSubmitting(true);
            navigate(`/users?${toSearchString({
                ...form,
                page: 0,
            })}`);
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const navigateToPage = (page) => {
        navigate(`/users?${toSearchString({
            ...search,
            page,
        })}`);
    };
    return (React.createElement(Flex, { width: "full", flexDirection: "column" },
        React.createElement(PageHeader, { title: "Users" },
            React.createElement(Button, { to: "/user/new", "data-testid": "create-user-button" }, "Create User")),
        React.createElement(FormProvider, { ...formMethods },
            React.createElement(Flex, { as: "form", gap: "20px", paddingBottom: "20px", onSubmit: formMethods.handleSubmit(onSubmit), width: "full", flexDirection: "row", alignItems: "end" },
                React.createElement(Form.Text, { name: "name", label: "Name", labelPosition: "top", width: "100%", fieldWidth: "100%", "data-testid": "name-user-form" }),
                React.createElement(Form.Text, { name: "email", label: "Email Address", labelPosition: "top", width: "100%", fieldWidth: "100%", "data-testid": "email-user-form" }),
                React.createElement(Form.Select, { name: "brokerageId", label: "Company", labelPosition: "top", width: "200px", isLoading: isBrokeragesLoading, "data-testid": "company-user-form" },
                    React.createElement("option", { value: "" }, "Any"),
                    brokerages.map((brokerage, index) => (React.createElement("option", { key: getKey('brokerage', index), value: brokerage.id }, brokerage.name)))),
                React.createElement(Button, { type: "submit", isLoading: isSubmitting, className: "min-w-[100px]", "data-testid": "search-user-submit-button" }, "Search"))),
        React.createElement(DataTable, { columns: columns, data: users, isLoading: isLoading, noDataMessage: "No users were found that match your search criteria", "data-testid": "users-table" }),
        React.createElement(Paginator, { ...{ totalPageCount, pageNumber }, onChange: navigateToPage, stickToBottom: true })));
};
