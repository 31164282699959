import React, { memo } from 'react';
import { Stack } from '@chakra-ui/react';
import { Form, Text } from '~components';
export const EscrowInformation = memo(({ disabled }) => {
    return (React.createElement(Stack, { direction: "column", width: "full" },
        React.createElement(Text, { colorScheme: "purple", className: "font-medium text-violet-800" }, "ESCROW INFORMATION"),
        React.createElement(Form.TextArea, { disabled: true, className: "w-[200px] text-sm text-right px-[3px] min-h-[30px] py-0 overflow-hidden resize-none opacity-100 border-none", name: "offer.titleCompany", label: "Title Company", size: "xs" }),
        React.createElement(Form.Currency, { disabled: disabled, name: "offer.earnestDepositAmount", label: "Earnest Deposit Amount (USD)", size: "xs", isRequired: true }),
        React.createElement(Form.DateTimePicker, { disabled: disabled, name: "offer.emdDeadline", label: "EMD Deadline", size: "xs", isRequired: true }),
        React.createElement(Form.DateTimePicker, { disabled: disabled, name: "offer.dueDiligenceExpirationDate", label: "Due Diligence Expiration Date", size: "xs", isRequired: true }),
        React.createElement(Form.DateTimePicker, { disabled: disabled, name: "offer.closeOfEscrowDate", label: "Close of Escrow", size: "xs", isRequired: true }),
        React.createElement(Form.DateTimePicker, { disabled: disabled, name: "offer.dueDiligenceStartDate", label: "Due Diligence Start Date", size: "xs", isRequired: true }),
        React.createElement(Form.Currency, { disabled: disabled, name: "offer.commissionTotal", label: "Commission Total", size: "xs", isRequired: true })));
});
