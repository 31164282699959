import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from '~components';
import { useDocTypes } from '~hooks';
import { getKey } from '~utils';
const FIELD_WIDTH = '100%';
export const FileNameForm = ({ onChange }) => {
    const [docTypes, isDocTypesLoading] = useDocTypes();
    const formMethods = useForm({
        mode: 'onChange',
    });
    const docTypeId = formMethods.watch('docTypeId');
    const docTypeOther = formMethods.watch('docTypeOther');
    useEffect(() => {
        onChange({ docTypeId, docTypeOther });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docTypeId, docTypeOther]);
    const [otherItem] = useMemo(() => docTypes.slice(-1), [docTypes]);
    return (React.createElement(FormProvider, { ...formMethods },
        React.createElement("div", { className: "mt-[15px] mb-[20px]" },
            React.createElement(Form.Select, { label: "Choose Document Type", name: "docTypeId", labelPosition: "top", width: FIELD_WIDTH, isLoading: isDocTypesLoading },
                React.createElement("option", { key: "no-selected-doc-type", value: "empty" }, "Select.."),
                docTypes.map((docType, index) => (React.createElement("option", { key: getKey('doc-type', index), value: docType.id }, docType.name))))),
        docTypeId && otherItem.id.toString() === docTypeId ? (React.createElement(Form.Text, { autoFocus: true, className: "mb-[20px]", labelPosition: "top", name: "docTypeOther", label: "Other Document Type Name", width: FIELD_WIDTH })) : null));
};
