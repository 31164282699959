/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FormField, MultiSelect } from '~components';
export const MultiSelectField = (props) => {
    const { name, size = 'md', label, minWidth, maxWidth, width = '150px', fieldWidth, labelPosition, helperText, isRequired, options, ...restOfProps } = props;
    return (React.createElement(FormField, { ...{ name, label, labelPosition, maxWidth, minWidth, width, fieldWidth, helperText, isRequired }, render: ({ ref, onChange, value, ...controllerProps }) => (React.createElement(MultiSelect, { size: size, onChange: (data) => {
                onChange(mapData(data));
            }, value: !value
                ? undefined
                : options?.filter((o) => {
                    // eslint-disable-next-line eqeqeq
                    return value.some((item) => item == o?.value);
                }), options: options, ...controllerProps, ...restOfProps })) }));
};
function mapData(data) {
    if (!data)
        return undefined;
    if (Array.isArray(data))
        return data.map((option) => option.value);
    return data.value;
}
