import { useStoreActions, useStoreState } from 'easy-peasy';
export function useStore() {
    const { setIsErrorModalShown, setApplicationFailure } = useStoreActions(mapActions);
    const { isErrorModalShown, applicationFailure } = useStoreState((state) => state.common);
    return {
        applicationFailure,
        isErrorModalShown,
        setIsErrorModalShown,
        setApplicationFailure: (origin, message) => {
            setApplicationFailure({ isActive: true, origin, message });
        },
        clearApplicationErrors: () => {
            setApplicationFailure({ isActive: false, origin: undefined, message: undefined });
        },
    };
}
function mapActions(actions) {
    return actions.common;
}
