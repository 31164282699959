import { axiosAuthorizedInstance, dataFromResponse, getApiBasePath } from '~services';
import { getSearchParams } from '~utils';
export async function getAll() {
    const url = `${getApiBasePath()}/admin/users`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
export async function getCurrent() {
    const url = `${getApiBasePath()}/admin/users/current`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
export async function create(request) {
    const url = `${getApiBasePath()}/admin/users`;
    return dataFromResponse(axiosAuthorizedInstance.post(url, request));
}
export async function update(request, userId) {
    const url = `${getApiBasePath()}/admin/users/${userId}`;
    return axiosAuthorizedInstance.put(url, request);
}
export async function resendInvite(userId) {
    const url = `${getApiBasePath()}/admin/users/${userId}/invite`;
    return axiosAuthorizedInstance.post(url);
}
export async function changeBrokerage(brokerageId) {
    const url = `${getApiBasePath()}/admin/users/change-brokerage?${getSearchParams({
        brokerageId,
    })}`;
    return axiosAuthorizedInstance.post(url);
}
