/* eslint-disable @typescript-eslint/naming-convention */
import { DateTimePickerField } from '~components/Form/DateTimePickerField';
import { CurrencyField } from './CurrencyField';
import { EmailField } from './EmailField';
import { MultiSelectField } from './MultiSelectField';
import { PasswordField } from './PasswordField';
import { PhoneField } from './PhoneField';
import { SelectField } from './SelectField';
import { TextareaField } from './TextareaField';
import { TextField } from './TextField';
export * from './FormField';
export const Form = {
    Email: EmailField,
    Password: PasswordField,
    TextArea: TextareaField,
    Text: TextField,
    Phone: PhoneField,
    Currency: CurrencyField,
    Select: SelectField,
    MultiSelect: MultiSelectField,
    DateTimePicker: DateTimePickerField,
};
