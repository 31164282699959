/* eslint-disable */
// @ts-nocheck
// disabled for pipeline to fix in next ticket
import React, { useMemo } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { Button, ConfirmModal, useModal } from '~components';
import { useFiles, useListing } from '~hooks';
import { TListingPageMode } from '~pages';
import { isEmpty } from '~utils';
import { ListingProfileSubmitErrorsList } from './listing-profile-submit-errors-list';
import { useFormContext } from 'react-hook-form';
export const ListingProfileSubmit = ({ submitErrors, setInitialFilesList, submitListingMutation, }) => {
    const [files] = useFiles();
    const [listing, isListingLoading] = useListing();
    const [modalProps, confirm] = useModal();
    const { handleSubmit, formState } = useFormContext();
    const isFormDisabled = listing.pageMode === TListingPageMode.FORM_DISABLED;
    const isSubmitNotAccepted = listing.pageMode === TListingPageMode.SUBMIT_NOT_ACCEPTED;
    const isFormButtonsDisabled = useMemo(() => isFormDisabled, [isFormDisabled]);
    const isSubmitButtonDisabled = isListingLoading || !isEmpty(submitErrors) || !formState.isValid || isFormButtonsDisabled || isSubmitNotAccepted;
    const submitTooltipLabel = useMemo(() => {
        if (isSubmitNotAccepted) {
            return 'Please accept offer to submit this listing';
        }
        if (isFormDisabled || !isSubmitButtonDisabled)
            return '';
        return React.createElement(ListingProfileSubmitErrorsList, { submitErrors: submitErrors });
    }, [isFormDisabled, isSubmitButtonDisabled, submitErrors, isSubmitNotAccepted]);
    const onFromSubmit = async ({ offer }) => {
        if (!isEmpty(submitErrors) || !formState.isValid)
            return;
        if (await confirm()) {
            await submitListingMutation({ ...offer });
            setInitialFilesList(files);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { hasArrow: true, label: submitTooltipLabel, bg: "red.600", shouldWrapChildren: true, placement: "bottom-start" },
            React.createElement(Button, { disabled: isSubmitButtonDisabled, onClick: handleSubmit(onFromSubmit) }, "SUBMIT")),
        React.createElement(ConfirmModal, { title: "Are you sure you want to submit Listing?", ok: "YES", cancel: "NO, RETURN TO LISTING", ...modalProps })));
};
