/* eslint-disable */
import { startOfDay } from 'date-fns';
import { addMethod, date, string } from 'yup';
export function addMethods() {
    addMethod(string, 'allowEmpty', allowEmpty);
    addMethod(date, 'minOrEqualDate', minOrEqualDate);
    addMethod(date, 'minDate', minDate);
    addMethod(date, 'maxDate', maxDate);
}
function allowEmpty() {
    return this.transform((value) => (value === '' ? undefined : value));
}
function minOrEqualDate(minDate, message) {
    return this.test('test-min-date', message, function (value) {
        return startOfDay(value) >= startOfDay(minDate);
    });
}
function minDate(minDate, message) {
    return this.test('test-min-date', message, function (value) {
        return startOfDay(value) > startOfDay(minDate);
    });
}
function maxDate(maxDate, message) {
    return this.test('test-max-date', message, function (value) {
        return startOfDay(value) < startOfDay(maxDate);
    });
}
