import React, { memo } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { AdditionalInformation, ContractInformation, EscrowInformation, HoaInformation } from '~pages';
export const ListingDetailsForm = memo(({ onFormChange, isFormDisabled, hasHOA }) => {
    return (React.createElement("form", { onChange: onFormChange },
        React.createElement(Stack, { direction: { base: 'column', md: 'row' }, spacing: 8, mx: "auto", width: "full" },
            React.createElement(ContractInformation, { disabled: isFormDisabled }),
            React.createElement(EscrowInformation, { disabled: isFormDisabled }),
            React.createElement(Flex, { flexDirection: "column", width: "full" },
                React.createElement(HoaInformation, { disabled: isFormDisabled, hasHOA: hasHOA }),
                React.createElement(AdditionalInformation, { disabled: isFormDisabled })))));
});
