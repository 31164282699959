import { axiosAuthorizedInstance, axiosDefaultInstance, dataFromResponse, getApiBasePath } from '~services';
export async function login(credentials) {
    const url = `${getApiBasePath()}/auth/login`;
    return dataFromResponse(axiosDefaultInstance.post(url, credentials));
}
export async function logout() {
    const url = `${getApiBasePath()}/auth/logout`;
    return axiosAuthorizedInstance.post(url, null);
}
export async function requestResetPassword(email) {
    const url = `${getApiBasePath()}/auth/reset-password`;
    return axiosDefaultInstance.post(url, { email });
}
export async function resetPassword(email, token, newPassword) {
    const url = `${getApiBasePath()}/auth/reset-password`;
    return axiosDefaultInstance.put(url, { email, token, newPassword });
}
