import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router';
import { Stack } from '@chakra-ui/react';
import { Button, Heading, Img, Text, TImage } from '~components';
import { useLayout, useStore } from '~hooks';
export const ErrorPage = () => {
    const { isMobile } = useLayout();
    const { applicationFailure } = useStore();
    const { setIsLoading } = useOutletContext();
    useEffect(() => {
        if (applicationFailure.isActive) {
            window.scrollTo(0, 0);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationFailure.isActive]);
    const tryAgain = () => {
        setIsLoading(true);
        window.location.href = applicationFailure.origin ?? '/';
        window.location.reload();
    };
    return (React.createElement(Stack, { className: "w-full self-center flex flex-col text-center justify-end items-center min-h-[250px] sm:min-h-[300px] h-[70vh] sm:h-[60vh]" },
        React.createElement(Img, { src: TImage.ERROR_PAGE, alt: "error", className: "absolute min-w-[200px] sm:min-w-[350px] top-[-20px] w-[50vh] sm:w-[70vh] object-cover" }),
        React.createElement(Stack, { alignItems: "center" },
            React.createElement(Heading, null,
                React.createElement("p", null, "Oops! Something went wrong.")),
            React.createElement(Text, { className: "block xs:hidden" },
                "Please push \"Try Again\" button ",
                isMobile ? React.createElement("br", null) : null,
                "to refresh your browser"),
            React.createElement(Text, { className: "hidden xs:block" }, "Please push \"Try Again\" button to refresh your browser"),
            React.createElement(Stack, { direction: isMobile ? 'column' : 'row' },
                React.createElement(Button, { className: "mt-5", onClick: tryAgain }, "Try again")))));
};
