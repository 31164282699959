import { useCallback } from 'react';
import debounce from 'debounce-promise';
import { isValidationError } from '~types';
export const useFormValidationErrors = (validationSchema) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(debounce(async (data) => validateForDisplay(data, validationSchema), 100), [validationSchema]);
};
export const useFormValidationResolver = (validationSchema) => {
    return [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(debounce(async (formMethods, data, context) => {
            try {
                const validationResult = await validateForDisplay(data, validationSchema, context);
                setFormErrors(formMethods.clearErrors, formMethods.setError, validationResult.errors);
                return validationResult;
            }
            catch (error) {
                console.error('Form validation resolver error:', error);
                return {
                    values: {},
                    errors: {},
                };
            }
        }, 100), [validationSchema]),
        useCallback((clearErrors, setError, errors) => {
            setFormErrors(clearErrors, setError, errors);
        }, []),
    ];
};
function setFormErrors(clearErrors, setError, errors) {
    clearErrors();
    if (!errors)
        return;
    Object.entries(errors).forEach(([id, error]) => {
        setError(id, error);
    });
}
async function validateForDisplay(data, schema, context) {
    try {
        const values = await schema.validate(data, {
            abortEarly: false,
            context,
        });
        return {
            values,
            errors: {},
        };
    }
    catch (errors) {
        return {
            values: {},
            errors: mapValidationSchemaErrors(errors),
        };
    }
}
function mapValidationSchemaErrors(errors) {
    if (!isValidationError(errors)) {
        console.error('A non validation error occurred on form validation', errors);
        return {};
    }
    return errors.inner.reduce((allErrors, currentError) => ({
        ...allErrors,
        [currentError.path]: {
            ...currentError,
            type: currentError.type,
        },
    }), {});
}
