/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getFilesKey, mapFileModel, useRouting } from '~hooks';
import { fileService } from '~services';
export const useUploadFile = () => {
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const KEY = getFilesKey(listingId);
    const uploadFileMutation = useMutation(async (data) => fileService.upload(data, listingId), {
        onMutate: async (data) => {
            await queryClient.cancelQueries(KEY);
            const newFile = { name: data.file.name };
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [newFile];
                return [...cachedData, newFile];
            });
        },
        onSuccess: (uploadedFile) => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [uploadedFile];
                return [...cachedData.filter((file) => !!file.id), uploadedFile];
            });
        },
        onError: () => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [];
                return [...cachedData.filter((file) => !!file.id)];
            });
        },
    });
    return {
        uploadFile: async (data, onSuccess, onError) => {
            await uploadFileMutation.mutateAsync(data, {
                onSuccess: (fileModel) => {
                    onSuccess(mapFileModel(fileModel));
                },
                onError: (apiError) => {
                    console.error('File upload error', apiError);
                    onError(apiError.message);
                },
            });
        },
        isUploading: uploadFileMutation.isLoading,
    };
};
