import { hasProperties } from '~utils';
export var TApiErrorCode;
(function (TApiErrorCode) {
    TApiErrorCode[TApiErrorCode["UNDEFINED"] = 0] = "UNDEFINED";
    TApiErrorCode[TApiErrorCode["INVALID_SIGN_IN_PASSWORD"] = 7400] = "INVALID_SIGN_IN_PASSWORD";
    TApiErrorCode[TApiErrorCode["UNKNOWN_SIGN_IN_EMAIL"] = 13400] = "UNKNOWN_SIGN_IN_EMAIL";
    TApiErrorCode[TApiErrorCode["SERVER_UNAVAILABLE"] = 500] = "SERVER_UNAVAILABLE";
    TApiErrorCode[TApiErrorCode["DUPLICATED_BROKERAGE"] = 1400] = "DUPLICATED_BROKERAGE";
    TApiErrorCode[TApiErrorCode["DUPLICATED_EMAIL"] = 1500] = "DUPLICATED_EMAIL";
})(TApiErrorCode || (TApiErrorCode = {}));
export const USER_VISIBLE_ERRORS = [TApiErrorCode.UNKNOWN_SIGN_IN_EMAIL, TApiErrorCode.INVALID_SIGN_IN_PASSWORD];
export function isApiError(object) {
    return hasProperties(object, ['data', 'errorCode', 'message']);
}
