import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConfirmModal, snackbar, useModal } from '~components';
import { useListing, usePrompt } from '~hooks';
import { toListingProfileFormModel } from '~pages';
export const ListingProfileLeavePageAction = ({ updateListingMutation, }) => {
    const [listing] = useListing();
    const [modalProps, confirm] = useModal();
    const { formState, reset, getValues } = useFormContext();
    const [isOpen, setIsOpen, next] = usePrompt(true);
    const unblockBackAction = () => {
        setIsOpen(false);
        next();
    };
    const handleSaveListingBeforeLeave = async () => {
        if (formState.isValid) {
            try {
                await updateListingMutation(getValues().offer);
                unblockBackAction();
            }
            catch {
                snackbar.error('Cannot save listing at the moment');
            }
        }
        else {
            snackbar.error('Cannot save listing due to validation errors');
        }
    };
    const confirmReturnPage = async () => {
        if (await confirm()) {
            await handleSaveListingBeforeLeave();
            return;
        }
        setIsOpen(false);
    };
    const handleReturnToListingPage = async () => {
        if (!formState.isDirty) {
            const formData = toListingProfileFormModel(listing);
            reset(formData);
            setIsOpen(false);
            next();
            return;
        }
        await confirmReturnPage();
    };
    const actions = [
        {
            title: 'Save Changes',
            variant: 'solid',
            type: 'submit',
        },
        {
            title: 'Return to Editing',
            variant: 'solid',
            type: 'reject',
            onClick: () => {
                modalProps.controls.reject();
                setIsOpen(false);
            },
        },
        {
            title: 'Return to Search without Saving',
            variant: 'link',
            type: 'reject',
            onClick: unblockBackAction,
        },
    ];
    useEffect(() => {
        if (isOpen)
            void handleReturnToListingPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    return React.createElement(ConfirmModal, { ...modalProps, title: "Your changes have not been saved", actions: actions, width: 540 });
};
