import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getAuthToken } from '~services';
import { TUserRole, userFromJwtToken } from '~types';
import { useLogin } from './useLogin';
import { useLogout } from './useLogout';
export const LOGIN_KEY = 'login';
export const getLoginKey = () => [LOGIN_KEY];
export function useAuth(options) {
    const queryClient = useQueryClient();
    const KEY = getLoginKey();
    const query = useQuery(KEY, async () => {
        const storedToken = getAuthToken();
        const result = storedToken ? { token: storedToken } : undefined;
        return Promise.resolve(result);
    });
    const loginMutation = useLogin(queryClient, KEY, options);
    const logoutMutation = useLogout(queryClient, KEY, options);
    const result = mapResult(query.data);
    return { ...result, ...loginMutation, ...logoutMutation, isLoggingIn: query.isLoading || loginMutation.isLoggingIn };
}
function mapResult(result) {
    if (!result?.token)
        return { role: TUserRole.UNDEFINED, isLoggedIn: false };
    return {
        isLoggedIn: true,
        ...userFromJwtToken(result.token),
    };
}
