/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { buildTableColumns, Button, DataTable, Form, PageHeader } from '~components';
import { useBrokerages, useRouting } from '~hooks';
import { tryParseAndFormat } from '~utils';
export const BrokeragesListPage = () => {
    const [brokerages, isLoading] = useBrokerages();
    const { search, toSearchString, navigate } = useRouting();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formMethods = useForm();
    useEffect(() => {
        formMethods.reset(search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = (form) => {
        if (isSubmitting)
            return;
        try {
            setIsSubmitting(true);
            navigate(`/companies?${toSearchString({
                ...form,
            })}`);
        }
        finally {
            setIsSubmitting(false);
        }
    };
    const brokeragesFilteredData = useMemo(() => {
        const brokerSearchName = search.name || '';
        if (brokerages.length && brokerSearchName) {
            return brokerages.filter((broker) => broker.name.toLowerCase().includes(brokerSearchName.toLowerCase().trim()));
        }
        return brokerages;
    }, [brokerages, search.name]);
    const columns = useMemo(() => buildTableColumns([
        ['name', 'Name'],
        ['address', 'Address'],
        ['email', 'Email Address'],
        ['phone', 'Telephone'],
        ['webSite', 'Website'],
        [(brokerage) => tryParseAndFormat(brokerage.lastModifiedDate), 'Last Modified'],
        [
            'id',
            {
                // eslint-disable-next-line react/no-unstable-nested-components
                cell: (cell) => (React.createElement(Button, { variant: "link", to: `/company/${cell.getValue()}` }, "Edit")),
            },
        ],
    ]), []);
    return (React.createElement(Flex, { width: "full", flexDirection: "column" },
        React.createElement(FormProvider, { ...formMethods },
            React.createElement(Flex, { as: "form", gap: "20px", paddingBottom: "20px", onSubmit: formMethods.handleSubmit(onSubmit), width: "50%", flexDirection: "row", alignItems: "end" },
                React.createElement(Form.Text, { name: "name", label: "Name", labelPosition: "top", width: "100%", fieldWidth: "100%", "data-testid": "name-brokerages-form" }),
                React.createElement(Button, { type: "submit", isLoading: isSubmitting, className: "min-w-[100px]", "data-testid": "search-brokerages-submit-button" }, "Search"))),
        React.createElement(PageHeader, { title: "Companies" },
            React.createElement(Button, { to: "/company/new", "data-testid": "create-company-button" }, "Create Company")),
        React.createElement(DataTable, { columns: columns, data: brokeragesFilteredData, isLoading: isLoading, "data-testid": "companies-table" })));
};
