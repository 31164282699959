/* eslint-disable no-template-curly-in-string */
import { differenceInYears, startOfDay } from 'date-fns';
import { boolean, date, number, string } from 'yup';
import { phoneRegExpOrWhitespace } from '~utils';
export * from './yupSchemaMethods';
export function allowEmpty() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return this.transform((value) => (value === '' ? undefined : value));
}
export const positiveNumber = number()
    .default(0)
    .min(0, 'Enter a positive whole ${path} number value')
    .nullable(true)
    .typeError('${path} value should be a number');
export const negativeNumber = number()
    .default(0)
    .max(0, 'Enter a negative whole ${path} number value')
    .nullable(true)
    .typeError('${path} value should be a number');
export const requiredStringField = string().required('${path} is missing').typeError('${path} value should be a string');
export const requiredNumberField = number().required('${path} is missing').typeError('${path} value should be a number');
export const requiredBooleanField = boolean().required('${path} is missing').typeError('${path} value should be a boolean');
export const phoneNumberOrEmptyChar = string().matches(phoneRegExpOrWhitespace, 'Phone number is not valid');
export const maxCharsValidation = (maxChars) => string().max(maxChars, `Field should not exceed ${maxChars} chars`);
export const dateWithinYearOfToday = date().test('year-range', "${path} should be within a year of today's date", (value) => {
    return value !== undefined && differenceInYears(new Date(), startOfDay(value)) < 1;
});
