import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getQueryMethods, getQueryStatus } from '~hooks';
import { adminServices } from '~services';
export const CURRENT_USER_KEY = 'current-user';
export const getCurrentUserKey = () => [CURRENT_USER_KEY];
export function useGetCurrentUser() {
    const queryClient = useQueryClient();
    const KEY = getCurrentUserKey();
    const query = useQuery(KEY, adminServices.users.getCurrent);
    const result = query.data ?? {};
    const status = getQueryStatus(query);
    return [result, status.isLoading, { ...getQueryMethods(query, queryClient, KEY), ...status }];
}
