import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StoreProvider } from 'easy-peasy';
import { ErrorBoundary, SnackBarContainer } from '~components';
import { theme } from '~config/chakra-ui.theme.js';
import { Routes } from '~routes';
import { store } from '~store';
import { TErrorReason } from '~types';
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            if (error?.message === TErrorReason.TOKEN_EXPIRED) {
                store.getActions().common.setApplicationFailure({ isActive: true, message: TErrorReason.TOKEN_EXPIRED });
            }
            else {
                console.error('Api error occurred', error);
            }
        },
    }),
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 100000,
        },
    },
});
export const App = ({ baseUrl }) => {
    return (
    // @ts-expect-error: Property 'children' does not exist on type
    React.createElement(StoreProvider, { store: store },
        React.createElement(ChakraProvider, { theme: theme },
            React.createElement(ErrorBoundary, null,
                React.createElement(QueryClientProvider, { client: queryClient },
                    React.createElement(BrowserRouter, { basename: baseUrl },
                        React.createElement(Routes, null))))),
        React.createElement(SnackBarContainer, null)));
};
