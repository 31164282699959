import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getQueryMethods, getQueryStatus, useRouting } from '~hooks';
import { fileService } from '~services';
import { useRemoveFile } from './useRemoveFile';
import { useUploadFile } from './useUploadFile';
export const FILES_KEY = 'files';
export const getFilesKey = (listingId) => [FILES_KEY, listingId];
export function useFiles() {
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const KEY = getFilesKey(listingId);
    const isEnabled = !!listingId;
    const query = useQuery(KEY, async () => fileService.getAll(listingId), {
        enabled: isEnabled,
        onError: () => { },
    });
    const result = (query.data ?? []).map(mapFileModel);
    const status = getQueryStatus(query);
    const upload = useUploadFile();
    const remove = useRemoveFile();
    const isLoading = isEnabled && (status.isLoading || upload.isUploading || remove.isRemoving);
    return [result, isLoading, { ...upload, ...remove, ...getQueryMethods(query, queryClient, KEY), ...status }];
}
export function mapFileModel(fileModel) {
    return {
        id: fileModel.id,
        name: fileModel.name,
        url: fileModel.url,
        isLoading: !fileModel.id,
        docTypeName: fileModel.docTypeName,
        docTypeOther: fileModel.docTypeOther,
    };
}
