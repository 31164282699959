import React from 'react';
import { Image as ChakraImage } from '@chakra-ui/react';
export var TImage;
(function (TImage) {
    TImage["ERROR_PAGE"] = "/error-page.svg";
    TImage["LOGIN_LOGO"] = "/login-logo.svg";
    TImage["LOGIN_BACKGROUND"] = "/login-background.png";
})(TImage || (TImage = {}));
export const Img = (props) => {
    return React.createElement(ChakraImage, { className: "object-none mx-auto max-w-min", ...props });
};
export const preloadImages = (imagesSrc) => {
    if (!imagesSrc || !Array.isArray(imagesSrc) || !imagesSrc.length)
        return;
    if (!window.imageCache?.values)
        window.imageCache = new Map();
    imagesSrc
        .filter((imageSrc) => !window.imageCache?.has(imageSrc))
        .forEach((imageSrc) => {
        const newImage = new Image();
        newImage.src = imageSrc;
        window.imageCache?.set(imageSrc, newImage);
    });
};
