// Blazing fast unique hash generator - https://github.com/bevacqua/hash-sum
/* eslint-disable */
function pad(hash, len) {
    let paddedHash = hash;
    while (paddedHash.length < len) {
        paddedHash = `0${paddedHash}`;
    }
    return paddedHash;
}
function fold(hash, text) {
    let i;
    let chr;
    let len;
    if (text.length === 0) {
        return hash;
    }
    let foldedHash = hash;
    for (i = 0, len = text.length; i < len; i++) {
        chr = text.charCodeAt(i);
        foldedHash = (foldedHash << 5) - foldedHash + chr;
        foldedHash |= 0;
    }
    return foldedHash < 0 ? foldedHash * -2 : foldedHash;
}
function foldObject(hash, o, seen) {
    return Object.keys(o).sort().reduce(foldKey, hash);
    function foldKey(h, key) {
        return foldValue(h, o[key], key, seen);
    }
}
function foldValue(input, value, key, seen) {
    const hash = fold(fold(fold(input, key), toString(value)), typeof value);
    if (value === null) {
        return fold(hash, 'null');
    }
    if (value === undefined) {
        return fold(hash, 'undefined');
    }
    if (typeof value === 'object' || typeof value === 'function') {
        if (seen.indexOf(value) !== -1) {
            return fold(hash, `[Circular]${key}`);
        }
        seen.push(value);
        const objHash = foldObject(hash, value, seen);
        if (!('valueOf' in value) || typeof value.valueOf !== 'function') {
            return objHash;
        }
        try {
            return fold(objHash, String(value.valueOf()));
        }
        catch (err) {
            return fold(objHash, `[valueOf exception]${err?.stack || err?.message}`);
        }
    }
    return fold(hash, value.toString());
}
function toString(o) {
    return Object.prototype.toString.call(o);
}
export function getHash(o) {
    return pad(foldValue(0, o, '', []).toString(16), 8);
}
