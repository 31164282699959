import { axiosAuthorizedInstance, dataFromResponse, getApiBasePath } from '~services';
import { getSearchParams } from '~utils';
export async function search(listingId, address, statuses, page = 0) {
    const url = `${getApiBasePath()}/listings?${getSearchParams({
        listingId,
        address,
        statuses,
        page,
        pageSize: 20,
    })}`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
export async function get(listingId) {
    const url = `${getApiBasePath()}/listings/${listingId}`;
    return dataFromResponse(axiosAuthorizedInstance.get(url));
}
export async function put(data) {
    const url = `${getApiBasePath()}/listings/offer`;
    return dataFromResponse(axiosAuthorizedInstance.put(url, data));
}
export async function post(data) {
    const url = `${getApiBasePath()}/listings/offer`;
    return dataFromResponse(axiosAuthorizedInstance.post(url, data));
}
export async function postOfferAccept(data) {
    const url = `${getApiBasePath()}/listings/accept`;
    return dataFromResponse(axiosAuthorizedInstance.post(url, data));
}
export async function postOfferReject(data) {
    const url = `${getApiBasePath()}/listings/reject`;
    return dataFromResponse(axiosAuthorizedInstance.post(url, data));
}
