/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Flex, Stack, useColorModeValue } from '@chakra-ui/react';
import { Button, ButtonBlock, Form } from '~components';
import { useAuth, useRouting } from '~hooks';
import { AuthLogo } from '~pages';
import { isApiError, TApiErrorCode } from '~types';
export const LoginPage = () => {
    const formMethods = useForm({ mode: 'onChange' });
    const { handleSubmit, setError } = formMethods;
    const { navigate } = useRouting();
    const { login, isLoggingIn } = useAuth({
        onError: (e) => {
            handleAuthErrors(setError, e);
        },
        onLogin: () => {
            navigate('/');
        },
    });
    return (React.createElement(Flex, { flexGrow: 1, align: "center", justify: "center" },
        React.createElement(Stack, { spacing: 8, mx: "auto", width: 600, py: 12, px: 6 },
            React.createElement(Box, { rounded: "20px", bg: useColorModeValue('white', 'gray.700'), p: 8 },
                React.createElement(AuthLogo, { className: "pb-4 mx-auto" }),
                React.createElement(FormProvider, { ...formMethods },
                    React.createElement(Stack, { spacing: 4, as: "form", onSubmit: handleSubmit(login) },
                        React.createElement(Form.Email, { name: "email", variant: "rounded", placeholder: "Enter your Email", label: "Your Email", labelPosition: "top", isRequired: true, "data-testid": "email-login-form" }),
                        React.createElement(Form.Password, { name: "password", variant: "rounded", placeholder: "Enter your Password", label: "Your Password", labelPosition: "top", isRequired: true, "data-testid": "password-login-form" }),
                        React.createElement(ButtonBlock, { direction: "row", justify: "end" },
                            React.createElement(Button, { variant: "link", to: "/forgot-password", "data-testid": "forgot-password-link" }, "Forgot password?")),
                        React.createElement(ButtonBlock, { direction: "row", justify: "center" },
                            React.createElement(Button, { isLoading: isLoggingIn, type: "submit", variant: "solid-rounded", width: 355, "data-testid": "sign-in-submit-button" }, "Sign In"))))))));
};
function handleAuthErrors(setError, e) {
    if (isApiError(e)) {
        if (e.errorCode === TApiErrorCode.UNKNOWN_SIGN_IN_EMAIL) {
            setError('email', {
                type: 'custom',
                message: 'No active account found',
            });
            return;
        }
        if (e.errorCode === TApiErrorCode.INVALID_SIGN_IN_PASSWORD) {
            setError('password', {
                type: 'custom',
                message: 'Password does not match our records',
            });
        }
    }
    else {
        throw e;
    }
}
