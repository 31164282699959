import React from 'react';
import { TiMediaPlay, TiMediaPlayReverse } from 'react-icons/ti';
import { Container, Next, PageGroup, Paginator as ChakraPaginator, Previous } from 'chakra-paginator';
export const Paginator = ({ totalPageCount, pageNumber = 0, firstPageNumber = 1, onChange, stickToBottom }) => {
    const baseStyles = {
        minWidth: 6,
        h: 6,
        fontSize: 'sm',
        variant: 'outline',
        borderWidth: 0,
        colorScheme: 'purple',
    };
    const normalStyles = {
        ...baseStyles,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _hover: {
            ...baseStyles,
            bg: 'purple.100',
            textColor: 'purple.600',
        },
    };
    const activeStyles = {
        ...baseStyles,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _hover: {
            ...baseStyles,
            bg: 'purple.600',
        },
        variant: 'solid',
    };
    if (totalPageCount < 2)
        return null;
    if (totalPageCount < pageNumber + firstPageNumber)
        return null;
    return (React.createElement(ChakraPaginator, { onPageChange: (selectedPage) => {
            onChange(selectedPage - firstPageNumber);
        }, pagesQuantity: totalPageCount, currentPage: pageNumber + firstPageNumber, activeStyles: activeStyles, normalStyles: normalStyles, outerLimit: 2, innerLimit: 1 },
        React.createElement(Container, { align: "right", justify: "end", w: "full", p: 0, marginTop: stickToBottom ? 'auto' : 0 },
            React.createElement(Previous, { variant: "link", colorScheme: "purple", minW: 5, w: 5, p: 0 },
                React.createElement(TiMediaPlayReverse, null)),
            React.createElement(PageGroup, { isInline: true, align: "center", spacing: 0 }),
            React.createElement(Next, { variant: "link", colorScheme: "purple", minW: 5, w: 5, p: 0 },
                React.createElement(TiMediaPlay, null)))));
};
