import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { USERS_KEY } from '~hooks';
import { adminServices } from '~services';
export const useUpdateUser = () => {
    const queryClient = useQueryClient();
    const KEY = [USERS_KEY];
    const updateUser = async (data) => {
        await adminServices.users.update(data, data.userId);
        return { ...data };
    };
    const updateUserMutation = useMutation(updateUser, {
        onSuccess: (updatedUser) => {
            // eslint-disable-next-line no-param-reassign
            updatedUser = { ...updatedUser, lastModifiedDate: new Date().toISOString() };
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [updatedUser];
                return [updatedUser, ...cachedData.filter((u) => u.userId !== updatedUser.userId)];
            });
            snackbar.success('User successfully updated');
        },
        onError: () => {
            snackbar.error('Failed to update user');
        },
    });
    return {
        updateUser: async (request) => updateUserMutation.mutateAsync(request),
        isUpdating: updateUserMutation.isLoading,
    };
};
