import React from 'react';
import NumberFormat from 'react-number-format';
import { InputGroup } from '@chakra-ui/react';
import { FormField, Input } from '~components';
export const CurrencyField = (props) => {
    const { name, size = 'md', label, maxWidth, width = '150px', labelPosition, helperText, isRequired, ...restOfProps } = props;
    const inputComponent = ({ customInputProps, ...inputProps }) => {
        return React.createElement(Input, { ...inputProps, ...customInputProps });
    };
    return (React.createElement(FormField, { ...{ name, label, labelPosition, helperText, maxWidth, width, isRequired }, render: ({ onChange, ...controllerProps }) => {
            return (React.createElement(InputGroup, { size: size, width: width, maxWidth: maxWidth ?? width },
                React.createElement(NumberFormat, { customInput: inputComponent, customInputProps: {
                        size,
                        name,
                    }, decimalScale: 0, thousandSeparator: true, prefix: "$", onValueChange: (values, sourceInfo) => {
                        if (sourceInfo.source === 'event')
                            onChange(values.floatValue);
                    }, ...controllerProps, ...restOfProps })));
        } }));
};
