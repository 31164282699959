import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmModal, Form, useModal } from '~components';
import { useBrokerages, useChangeUserBrokerage, useGetCurrentUser } from '~hooks';
import { getKey } from '~utils';
export const AdminBrokerageForm = () => {
    const [currentUser, isGetCurrentUserLoading, { refetch }] = useGetCurrentUser();
    const changeUserBrokerageMutation = useChangeUserBrokerage();
    const [modalProps] = useModal();
    const [brokerages, isBrokeragesLoading] = useBrokerages();
    const { brokerageId } = currentUser;
    const isChangeBrokerageProcessing = changeUserBrokerageMutation.isUpdating || isGetCurrentUserLoading;
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: { adminBrokerageId: brokerageId },
    });
    useEffect(() => {
        formMethods.reset({ adminBrokerageId: brokerageId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brokerageId]);
    const onBrokerageChange = async (targetValue) => {
        await changeUserBrokerageMutation.changeUserBrokerage(Number(targetValue));
        await refetch();
    };
    return (React.createElement(FormProvider, { ...formMethods },
        React.createElement(Form.Select, { disabled: isChangeBrokerageProcessing, onChange: ({ target }) => {
                void onBrokerageChange(target.value);
            }, name: "adminBrokerageId", labelPosition: "top", width: "150px", isLoading: isBrokeragesLoading },
            !brokerageId && (React.createElement("option", { key: "no-brokerage", value: "0", className: "bg-purple-600" }, "No brokerage")),
            brokerages.map((brokerage, index) => (React.createElement("option", { key: getKey('brokerage', index), value: brokerage.id, className: "bg-purple-600" }, brokerage.name)))),
        React.createElement(ConfirmModal, { title: "Are you sure you want to sign out to view another company's listings?", ...modalProps })));
};
