import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getListingKey, useListingModel, useRouting } from '~hooks';
import { listingsService } from '~services';
export function useUpdateListing() {
    const { toRequestObject } = useListingModel();
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const LISTING_KEY = getListingKey(listingId);
    const updateListing = async (offer) => {
        const requestData = toRequestObject(offer);
        const { id, status, hasError, errorMessage, pageMode } = await listingsService.put(requestData);
        return { ...requestData, id, hasError, errorMessage, status, pageMode };
    };
    return useMutation(updateListing, {
        onSuccess: (updatedOfferData) => {
            queryClient.setQueryData(LISTING_KEY, (cachedData) => {
                if (!cachedData)
                    return undefined;
                return { ...cachedData, offer: { ...cachedData.offer, ...updatedOfferData } };
            });
            if (updatedOfferData.hasError) {
                snackbar.error(updatedOfferData.errorMessage);
            }
            else {
                snackbar.success('Changes Successfully Saved');
            }
        },
        onError: () => {
            snackbar.error('Saving Failed. Try Again Later');
        },
    });
}
