import React from 'react';
import NumberFormat from 'react-number-format';
import { InputGroup } from '@chakra-ui/react';
import { FormField, Input } from '~components';
export const PhoneField = (props) => {
    const { name, maxWidth, width = '150px', size = 'md', label, labelPosition, helperText, isRequired, ...restOfProps } = props;
    const inputComponent = ({ customInputProps, ...inputProps }) => {
        return React.createElement(Input, { ...inputProps, ...customInputProps });
    };
    return (React.createElement(FormField, { ...{ name, label, labelPosition, helperText, isRequired, maxWidth, width }, render: ({ ...controllerProps }) => (React.createElement(InputGroup, { size: size, width: width, maxWidth: maxWidth ?? width },
            React.createElement(NumberFormat, { customInput: inputComponent, customInputProps: {
                    size,
                    name,
                }, format: "###-###-####", mask: "_", ...controllerProps, ...restOfProps }))) }));
};
