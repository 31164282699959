export var TOfferStatus;
(function (TOfferStatus) {
    TOfferStatus[TOfferStatus["NOT_STARTED"] = 1] = "NOT_STARTED";
    TOfferStatus[TOfferStatus["INCOMPLETE"] = 2] = "INCOMPLETE";
    TOfferStatus[TOfferStatus["SUBMITTED"] = 3] = "SUBMITTED";
    TOfferStatus[TOfferStatus["PENDING"] = 4] = "PENDING";
    TOfferStatus[TOfferStatus["REJECTED"] = 5] = "REJECTED";
    TOfferStatus[TOfferStatus["READY_FOR_ESCROW_INFO"] = 6] = "READY_FOR_ESCROW_INFO";
})(TOfferStatus || (TOfferStatus = {}));
export var TOfferStatusLabels;
(function (TOfferStatusLabels) {
    TOfferStatusLabels["NOT_STARTED"] = "Not started";
    TOfferStatusLabels["INCOMPLETE"] = "Incomplete";
    TOfferStatusLabels["SUBMITTED"] = "Submitted";
    TOfferStatusLabels["PENDING"] = "Pending";
    TOfferStatusLabels["REJECTED"] = "Rejected";
    TOfferStatusLabels["READY_FOR_ESCROW_INFO"] = "Ready for escrow info";
})(TOfferStatusLabels || (TOfferStatusLabels = {}));
