import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth, useRouting, useStore } from '~hooks';
import { AuthLayout, BaseLayout } from '~layouts';
import { BrokerageProfilePage, BrokeragesListPage, ErrorPage, ForgotPasswordPage, HomePage, ListingProfilePage, ListingsListPage, LoginPage, NewPasswordPage, UserProfilePage, UsersListPage, } from '~pages';
import { TErrorReason, TUserRole } from '~types';
import { PrivateRoute } from './PrivateRoute';
import { SalesforceRoute } from './SalesforceRoute';
export const routes = [
    {
        path: '',
        element: React.createElement(BaseLayout, null),
        children: [
            {
                path: '',
                element: React.createElement(PrivateRoute, { element: React.createElement(HomePage, null) }),
                children: [
                    { path: '', element: React.createElement(Navigate, { to: "/listings" }) },
                    {
                        path: 'listings',
                        element: React.createElement(SalesforceRoute, { element: React.createElement(ListingsListPage, null) }),
                    },
                    {
                        path: 'listing',
                        children: [
                            { path: '', element: React.createElement(Navigate, { to: "/listings" }) },
                            { path: ':listingId', element: React.createElement(SalesforceRoute, { element: React.createElement(ListingProfilePage, null) }) },
                        ],
                    },
                    {
                        path: 'users',
                        element: React.createElement(PrivateRoute, { role: TUserRole.ADMIN, element: React.createElement(UsersListPage, null) }),
                    },
                    {
                        path: 'user',
                        children: [
                            { path: '', element: React.createElement(Navigate, { to: "/users" }) },
                            { path: 'new', element: React.createElement(PrivateRoute, { role: TUserRole.ADMIN, element: React.createElement(UserProfilePage, { newUserMode: true }) }) },
                            { path: ':userId', element: React.createElement(PrivateRoute, { role: TUserRole.ADMIN, element: React.createElement(UserProfilePage, null) }) },
                        ],
                    },
                    {
                        path: 'companies',
                        element: React.createElement(PrivateRoute, { role: TUserRole.ADMIN, element: React.createElement(BrokeragesListPage, null) }),
                    },
                    {
                        path: 'company',
                        children: [
                            { path: '', element: React.createElement(Navigate, { to: "/companies" }) },
                            {
                                path: 'new',
                                element: React.createElement(PrivateRoute, { role: TUserRole.ADMIN, element: React.createElement(BrokerageProfilePage, { newBrokerageMode: true }) }),
                            },
                            { path: ':brokerageId', element: React.createElement(PrivateRoute, { role: TUserRole.ADMIN, element: React.createElement(BrokerageProfilePage, null) }) },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: React.createElement(AuthLayout, null),
        children: [
            {
                path: '',
                element: React.createElement(LoginPage, null),
            },
        ],
    },
    {
        path: '/forgot-password',
        element: React.createElement(AuthLayout, null),
        children: [
            {
                path: '',
                element: React.createElement(ForgotPasswordPage, null),
            },
        ],
    },
    {
        path: '/resetPassword',
        element: React.createElement(AuthLayout, null),
        children: [
            {
                path: '',
                element: React.createElement(NewPasswordPage, null),
            },
        ],
    },
];
const errorRoutes = [
    {
        path: '*',
        element: React.createElement(BaseLayout, null),
        children: [
            {
                path: '*',
                element: React.createElement(ErrorPage, null),
            },
        ],
    },
];
export const Routes = () => {
    const { applicationFailure, clearApplicationErrors } = useStore();
    const { navigate } = useRouting();
    const { logout } = useAuth({
        logoutClientOnly: true,
    });
    return useRoutes(...getRoutes(applicationFailure, logout, navigate, clearApplicationErrors));
};
function getRoutes(applicationFailure, logout, navigate, clearApplicationErrors) {
    if (!applicationFailure.isActive)
        return [routes];
    switch (applicationFailure.message) {
        case TErrorReason.TOKEN_EXPIRED:
            clearApplicationErrors();
            logout();
            navigate('/login');
            return [routes];
        default:
            return [errorRoutes];
    }
}
