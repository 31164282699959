/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex, Stack } from '@chakra-ui/react';
import { Button, Form, PageHeader, snackbar, Spinner } from '~components';
import { useBrokerages, useCreateBrokerage, useFormValidationResolver, useRouting, useUpdateBrokerage } from '~hooks';
import { TApiErrorCode } from '~types';
import { brokerageProfileValidationSchema } from './brokerage-profile.validation';
const FIELD_WIDTH = '300px';
export const BrokerageProfilePage = ({ newBrokerageMode }) => {
    const { brokerageId } = useRouting();
    const [validationResolver] = useFormValidationResolver(brokerageProfileValidationSchema);
    const formMethods = useForm({
        resolver: async (data) => validationResolver(formMethods, data),
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { updateBrokerage } = useUpdateBrokerage();
    const { createBrokerage } = useCreateBrokerage();
    const [brokerages, isBrokerageLoading] = useBrokerages();
    const brokerage = useMemo(() => brokerages.find((b) => `${b.id}` === brokerageId) ?? {}, [brokerageId, brokerages]);
    useEffect(() => {
        formMethods.reset(brokerage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(brokerage)]);
    const onSubmit = async (form) => {
        if (isSubmitting)
            return;
        try {
            setIsSubmitting(true);
            if (!newBrokerageMode) {
                await updateBrokerage({ ...brokerage, ...form });
            }
            else {
                await createBrokerage({ ...brokerage, ...form });
            }
        }
        catch (error) {
            if (error.errorCode === TApiErrorCode.DUPLICATED_BROKERAGE) {
                snackbar.error(error.message);
            }
            else {
                snackbar.error('Failed to update brokerage');
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };
    return (React.createElement(Spinner, { isLoading: isBrokerageLoading },
        React.createElement(Flex, { flexDirection: "column", width: "full" },
            React.createElement(PageHeader, { title: newBrokerageMode ? 'Create New Company' : 'Company Profile' },
                React.createElement(Button, { to: "/companies" }, "Return to Companies")),
            React.createElement(FormProvider, { ...formMethods },
                React.createElement(Stack, { spacing: 4, as: "form", onSubmit: formMethods.handleSubmit(onSubmit), width: "fit-content" },
                    React.createElement(Form.Text, { name: "name", label: "Name", isRequired: newBrokerageMode, width: FIELD_WIDTH }),
                    React.createElement(Form.Text, { name: "address", label: "Address", isRequired: newBrokerageMode, width: FIELD_WIDTH }),
                    React.createElement(Form.Text, { name: "email", label: "Email", isRequired: newBrokerageMode, width: FIELD_WIDTH }),
                    React.createElement(Form.Text, { name: "phone", label: "Telephone", isRequired: newBrokerageMode, width: FIELD_WIDTH }),
                    React.createElement(Form.Text, { name: "webSite", label: "Website", width: FIELD_WIDTH }),
                    React.createElement(Stack, { spacing: 10 },
                        !newBrokerageMode && (React.createElement(Button, { type: "submit", isLoading: isSubmitting }, "Save Changes")),
                        !!newBrokerageMode && (React.createElement(Button, { type: "submit", isLoading: isSubmitting }, "Create New Company"))))))));
};
