import React from 'react';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput as ChakraNumberInput, NumberInputField, NumberInputStepper, } from '@chakra-ui/react';
export const NumberInput = (props) => {
    const { size = 'md', variant = 'outline', className, innerRef, placeholder, maxWidth, ...restOfInputProps } = props;
    return (React.createElement(ChakraNumberInput, { "aria-label": placeholder, placeholder: placeholder, ref: innerRef, focusBorderColor: "purple.400", maxWidth: maxWidth, sx: { backgroundColor: 'var(--color-white)' }, ...{ size, variant }, ...restOfInputProps },
        React.createElement(NumberInputField, { className: className }),
        React.createElement(NumberInputStepper, null,
            React.createElement(NumberIncrementStepper, null),
            React.createElement(NumberDecrementStepper, null))));
};
export const NumberInputTheme = {
    sizes: {
        md: {
            field: {
                borderRadius: 'none',
            },
        },
    },
    defaultProps: {
        focusBorderColor: 'purple.400',
    },
};
