/* eslint-disable no-template-curly-in-string */
import { addDays } from 'date-fns';
import { date, number, object, string } from 'yup';
import { THasHoa } from '~types';
import { addMethods, dateWithinYearOfToday, maxCharsValidation, phoneNumberOrEmptyChar, positiveNumber, requiredNumberField } from '~utils';
addMethods();
const hasRequiredHOAValue = (previousSchema) => previousSchema.when(['hasHOA'], (hasHOA, schema) => {
    if (hasHOA === THasHoa.YES)
        return schema.required();
    return schema.nullable(true);
});
export const listingProfileValidationSaveSchema = object().shape({
    offer: object().shape({
        initialOfferDate: dateWithinYearOfToday.label('Initial Offer'),
        offerBindingDate: dateWithinYearOfToday
            .when(['initialOfferDate'], (initialOfferDate, schema) => {
            return schema.minOrEqualDate(initialOfferDate, '${path} should be after or equal Initial Offer');
        })
            .label('Offer Binding'),
        finalPurchasePrice: number().required().min(100000).max(650000).label('Final Purchase Price'),
        listingAgentName: maxCharsValidation(100).label('Name'),
        listingAgentEmail: maxCharsValidation(100).email().label('Agent Email'),
        listingAgentPhone: phoneNumberOrEmptyChar.label('Phone'),
        sellerName: maxCharsValidation(100).label('Seller Name'),
        earnestDepositAmount: number().required().min(0, 'Value must be positive').max(9999, 'Value cannot be greater than 9999'),
        closeOfEscrowDate: dateWithinYearOfToday
            .when(['offerBindingDate', 'dueDiligenceExpirationDate'], 
        // @ts-expect-error
        (offerBindingDate, dueDiligenceExpirationDate, schema) => {
            return schema
                .minOrEqualDate(addDays(offerBindingDate, 21), '${path} should be after or equal Offer Binding + 21 day')
                .minDate(dueDiligenceExpirationDate, '${path} should be after DD Expiration');
        })
            .label('Close Of Escrow'),
        emdDeadline: dateWithinYearOfToday
            .when(['offerBindingDate', 'closeOfEscrowDate'], 
        // @ts-expect-error
        (offerBindingDate, closeOfEscrowDate, schema) => {
            return schema
                .minDate(offerBindingDate, '${path} should be after Offer Binding')
                .maxDate(closeOfEscrowDate, '${path} should be before Close Of Escrow');
        })
            .label('EMD Deadline'),
        dueDiligenceStartDate: dateWithinYearOfToday
            .when(['offerBindingDate'], (offerBindingDate, schema) => {
            return schema.minOrEqualDate(offerBindingDate, '${path} should be after or equal Offer Binding');
        })
            .label('Start Date'),
        dueDiligenceExpirationDate: dateWithinYearOfToday
            .when(['dueDiligenceStartDate'], (dueDiligenceStartDate, schema) => {
            return schema.minOrEqualDate(dueDiligenceStartDate, '${path} should be after or equal DD Start');
        })
            .label('Expiration Date'),
        commissionTotal: number().required().label('Commission Total'),
        hasHOA: string()
            .test('hasHOA', 'Hoa Must be defined by yes or no', (val) => val !== THasHoa.UNKNOWN)
            .label('Has Hoa'),
        wellWaterProvider: maxCharsValidation(200).label('Well/Water Provider'),
        electricProvider: maxCharsValidation(200).label('Electric Provider'),
        sewerSepticProvider: maxCharsValidation(200).label('Sewer/Septic Provider'),
        gasProvider: maxCharsValidation(200).label('Gas Provider'),
        lockBox: maxCharsValidation(50).label('LockBox'),
        accessMethod: maxCharsValidation(50).label('Access Method'),
        gateCode: maxCharsValidation(25).label('Gate Code'),
    }),
});
export const listingProfileRequiredSubmitSchema = object().shape({
    offer: object().shape({
        initialOfferDate: date().required().label('Initial Offer Date'),
        offerBindingDate: date().required().label('Offer Binding'),
        finalPurchasePrice: number().required().label('Final Purchase Price'),
        listingAgentName: string().required().label('Agent Name'),
        listingAgentEmail: string().required().label('Agent Email'),
        listingAgentPhone: phoneNumberOrEmptyChar.required().label('Agent Phone'),
        sellerName: string().required().label('Seller Name'),
        titleCompany: string().label('Title Company'),
        earnestDepositAmount: requiredNumberField.label('Earnest Deposit Amount'),
        emdDeadline: date().required().label('Emd Deadline'),
        dueDiligenceStartDate: date().required().label('DD Start Date'),
        dueDiligenceExpirationDate: date().required().label('DD Expiration Date'),
        closeOfEscrowDate: date().required().label('Close Of Escrow Date'),
        commissionTotal: number().required().label('Commission Total'),
        wellWaterProvider: string().required().label('Well / Water Provider'),
        electricProvider: string().required().label('Electric Provider'),
        sewerSepticProvider: string().required().label('Sewer Septic Provider'),
        gasProvider: string().required().label('Gas Provider'),
        lockBox: string().required().label('LockBox'),
        accessMethod: string().required().label('Access Method'),
        occupancy: number().required().label('Occupancy'),
        gateCode: maxCharsValidation(25).label('Gate Code'),
        hoaName: hasRequiredHOAValue(maxCharsValidation(100)).label('Hoa Name'),
        hoaWebsite: hasRequiredHOAValue(maxCharsValidation(100)).label('Hoa Website'),
        hoaPhone: hasRequiredHOAValue(phoneNumberOrEmptyChar).label('Hoa Phone'),
        hoaTransferFee: hasRequiredHOAValue(positiveNumber).label('HOA Transfer Fees'),
        hoaFee: hasRequiredHOAValue(positiveNumber).label('HOA Fees'),
        hoaFrequency: hasRequiredHOAValue(string()).label('HOA Frequency'),
    }),
});
