import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '~hooks';
export const PrivateRoute = ({ role, element }) => {
    const { isLoggedIn, isLoggingIn, role: userRole, logout } = useAuth();
    if (isLoggingIn)
        return null;
    if (isLoggedIn) {
        if (!role || role === userRole)
            return element;
        logout();
    }
    return React.createElement(Navigate, { to: "/login" });
};
