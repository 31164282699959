import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getQueryMethods, getQueryStatus } from '~hooks';
import { fileService } from '~services';
export const DOC_TYPES_KEY = ['doc-types'];
export function useDocTypes() {
    const queryClient = useQueryClient();
    const query = useQuery(DOC_TYPES_KEY, async () => fileService.getDocTypes(), {
        onError: () => { },
    });
    const result = query.data ?? [];
    const status = getQueryStatus(query);
    return [result, status.isLoading, { ...getQueryMethods(query, queryClient, DOC_TYPES_KEY), ...status }];
}
