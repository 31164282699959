import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { Img, SideBar, Spinner } from '~components';
export const BaseLayout = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [sideBarContent, setSideBarContent] = useState(null);
    return (React.createElement("div", { className: "flex min-h-screen" },
        React.createElement(SideBar, null,
            React.createElement(Img, { src: "logo.png", alt: "FirstKeyHomes", className: "w-[151px] ml-[10px]" }),
            sideBarContent),
        React.createElement("div", { className: "flex flex-col w-full" },
            React.createElement(Spinner, { isLoading: isLoading, isFullScreen: true },
                React.createElement(Outlet, { context: { setSideBarContent, setIsLoading } })))));
};
