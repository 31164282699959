import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getBrokeragesKey } from '~hooks';
import { adminServices } from '~services';
export const useCreateBrokerage = () => {
    const queryClient = useQueryClient();
    const KEY = getBrokeragesKey();
    const createBrokerage = async (data) => {
        return { ...data, id: (await adminServices.brokerages.create(data)).id };
    };
    const createBrokerageMutation = useMutation(createBrokerage, {
        onSuccess: (updatedBrokerage) => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [updatedBrokerage];
                return [updatedBrokerage, ...cachedData];
            });
            snackbar.success('Company successfully created');
        },
    });
    return {
        createBrokerage: async (request) => createBrokerageMutation.mutateAsync(request),
        isCreating: createBrokerageMutation.isLoading,
    };
};
