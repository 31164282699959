import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getListingKey, useListingModel, useRouting } from '~hooks';
import { listingsService } from '~services';
export function useSubmitListing() {
    const { toRequestObject } = useListingModel();
    const queryClient = useQueryClient();
    const { listingId } = useRouting();
    const LISTING_KEY = getListingKey(listingId);
    const submitListing = async (offer) => {
        const requestData = toRequestObject(offer);
        const { status, id, hasError, errorMessage, pageMode } = await listingsService.post(requestData);
        return { ...requestData, id, status, hasError, errorMessage, pageMode };
    };
    return useMutation(submitListing, {
        onSuccess: (updatedOfferData) => {
            queryClient.setQueryData(LISTING_KEY, (cachedData) => {
                if (!cachedData)
                    return undefined;
                return { ...cachedData, offer: { ...cachedData.offer, ...updatedOfferData }, pageMode: updatedOfferData.pageMode };
            });
            if (updatedOfferData.hasError) {
                snackbar.error(updatedOfferData.errorMessage);
            }
            else {
                snackbar.success('Submitted successfully');
            }
        },
        onError: () => {
            snackbar.error('Submission Pending Review');
        },
    });
}
