import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import './snack-bar.less';
export const SnackBarContainer = () => (React.createElement(ToastContainer, { position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide, newestOnTop: true, limit: 1, hideProgressBar: true, autoClose: 3000, closeOnClick: true, closeButton: false, draggable: false }));
export function success(message) {
    toast.success(message);
}
export function warning(message) {
    toast.warning(message, { autoClose: 6000 });
}
export function error(message) {
    toast.error(message, { autoClose: 6000 });
}
export const snackbar = {
    success,
    warning,
    error,
};
