const USER_AUTH_KEY = 'userAuth';
export function setAuthToken(token) {
    localStorage.setItem(USER_AUTH_KEY, token);
}
export function removeAuthToken() {
    localStorage.removeItem(USER_AUTH_KEY);
}
export function getAuthToken() {
    return localStorage.getItem(USER_AUTH_KEY);
}
