import axios from 'axios';
import { mapError } from './mapError';
export const axiosDefaultInstance = axios.create();
axiosDefaultInstance.interceptors.request.use((config) => {
    if (config.headers) {
        config.headers.accept = '*/*';
    }
    return config;
}, async (error) => {
    return Promise.reject(error);
});
axiosDefaultInstance.interceptors.response.use((response) => {
    // Any status code 2xx trigger
    return response;
}, async (error) => {
    const mappedError = mapError(error);
    return Promise.reject(mappedError);
});
