import { useQuery, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getQueryMethods, getQueryStatus, useRouting } from '~hooks';
import { adminServices } from '~services';
const PAGE_SIZE = 10;
export const USERS_KEY = 'users';
export const getUsersKey = (brokerageId, name, email, page = 0) => [
    USERS_KEY,
    brokerageId,
    name,
    email,
    page,
];
export function useUsers(page = 0, userId) {
    const queryClient = useQueryClient();
    const { search: { brokerageId, name, email }, } = useRouting();
    const KEY = getUsersKey(brokerageId, name, email, 0);
    const query = useQuery(KEY, adminServices.users.getAll, {
        onError: () => {
            snackbar.error('Cannot fetch users at the moment');
        },
    });
    const filteredData = filter(query.data, brokerageId, name, email);
    const result = userId
        ? filteredData.filter((user) => user.userId === userId)
        : filteredData.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);
    const totalPageCount = Math.floor(filteredData.length / PAGE_SIZE) + 1;
    const pageNumber = page;
    const status = getQueryStatus(query);
    return [result, status.isLoading, { totalPageCount, pageNumber, ...getQueryMethods(query, queryClient, KEY), ...status }];
}
function filter(data, brokerageId, name, email) {
    if (!data)
        return [];
    return data.filter((user) => isUserMatchesFilter(user, brokerageId, name, email));
}
function isUserMatchesFilter(user, brokerageId, name, email) {
    if (brokerageId && `${user.brokerageId}` !== brokerageId)
        return false;
    if (name && !user.name.toLowerCase().includes(name.toLowerCase().trim()))
        return false;
    if (email && !user.email.toLowerCase().includes(email.toLowerCase().trim()))
        return false;
    return true;
}
