/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex, Stack } from '@chakra-ui/react';
import { Button, ButtonBlock, ConfirmModal, Form, PageHeader, snackbar, Spinner, useModal } from '~components';
import { useAuth, useBrokerages, useCreateUser, useFormValidationResolver, useRouting, useUpdateUser, useUsers } from '~hooks';
import { adminServices } from '~services';
import { TApiErrorCode, TUserRole } from '~types';
import { getKey } from '~utils';
import { userProfileValidationSchema } from './user-profile.validation';
const FIELD_WIDTH = '300px';
export const UserProfilePage = ({ newUserMode }) => {
    const { role } = useAuth();
    const { userId, search, toSearchString, navigate } = useRouting();
    const [brokerages, isBrokeragesLoading] = useBrokerages();
    const [, , { invalidate: invalidateUsers }] = useUsers();
    const [validationResolver] = useFormValidationResolver(userProfileValidationSchema);
    const formMethods = useForm({
        mode: 'onChange',
        resolver: async (data) => validationResolver(formMethods, data),
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { updateUser } = useUpdateUser();
    const { createUser } = useCreateUser();
    const [users, isUserLoading] = useUsers(undefined, userId);
    const user = useMemo(() => users[0], [users]);
    const parentUrl = useMemo(() => `/users?${toSearchString(search)}`, [search, toSearchString]);
    const [confirmToggleUserModalProps, confirmToggleUser] = useModal();
    const [confirmUpsertUserModalProps, confirmUpsertUser] = useModal();
    const [confirmResendInviteModalProps, confirmResendInvite] = useModal();
    useEffect(() => {
        if (!brokerages.length)
            return;
        if (newUserMode) {
            formMethods.reset({ role: 'user', brokerageId: brokerages[0].id });
        }
        else {
            formMethods.reset({ ...user, role: 'user' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(user), brokerages]);
    const onSubmit = async (form) => {
        if (isSubmitting)
            return;
        if (!(await confirmUpsertUser()))
            return;
        try {
            setIsSubmitting(true);
            if (!newUserMode) {
                await updateUser({ ...user, ...form });
            }
            else {
                await createUser({ ...user, ...form });
            }
        }
        catch (error) {
            if (error.errorCode === TApiErrorCode.DUPLICATED_EMAIL) {
                snackbar.error(error.message);
            }
            else {
                snackbar.error('Failed to create user');
            }
        }
        finally {
            setIsSubmitting(false);
            await invalidateUsers();
            navigate(parentUrl);
        }
    };
    const toggleUserIsEnabled = async (form) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (isSubmitting || !user)
            return;
        if (!(await confirmToggleUser()))
            return;
        try {
            setIsSubmitting(true);
            await updateUser({ ...user, ...form, isEnabled: !user.isEnabled });
        }
        finally {
            setIsSubmitting(false);
            await invalidateUsers();
            navigate(parentUrl);
        }
    };
    const resendInvite = async () => {
        if (isSubmitting || !userId)
            return;
        if (!(await confirmResendInvite()))
            return;
        try {
            setIsSubmitting(true);
            await adminServices.users.resendInvite(userId);
        }
        finally {
            setIsSubmitting(false);
        }
    };
    return (React.createElement(Spinner, { isLoading: isUserLoading },
        React.createElement(Flex, { flexDirection: "column", width: "full" },
            React.createElement(PageHeader, { title: newUserMode ? 'Create New User' : 'User Profile' },
                React.createElement(Button, { to: parentUrl }, "Return to Users")),
            React.createElement(FormProvider, { ...formMethods },
                React.createElement(Stack, { spacing: 4, as: "form", onSubmit: formMethods.handleSubmit(onSubmit), width: "fit-content" },
                    React.createElement(Flex, { flexDirection: "row", width: "full", justifyContent: "space-between" }, !newUserMode && (React.createElement(ButtonBlock, { direction: "row", width: "full" },
                        user?.isEnabled ? (React.createElement(Button, { full: true, variant: "outline", isDisabled: isSubmitting, onClick: formMethods.handleSubmit(toggleUserIsEnabled) }, "Disable User")) : (React.createElement(Button, { full: true, isDisabled: isSubmitting, onClick: formMethods.handleSubmit(toggleUserIsEnabled) }, "Enable User")),
                        React.createElement(Button, { full: true, isDisabled: isSubmitting, onClick: resendInvite }, "Resend Invite")))),
                    React.createElement(Form.Text, { name: "name", label: "Name", isRequired: newUserMode, width: FIELD_WIDTH }),
                    React.createElement(Form.Email, { name: "email", label: "Email", isRequired: newUserMode, width: FIELD_WIDTH }),
                    React.createElement(Form.Phone, { name: "phone", label: "Telephone", isRequired: newUserMode, width: FIELD_WIDTH }),
                    role === TUserRole.ADMIN && (React.createElement(Form.Select, { name: "brokerageId", label: "Company", isRequired: newUserMode, width: FIELD_WIDTH, isLoading: isBrokeragesLoading }, brokerages.map((brokerage, index) => (React.createElement("option", { key: getKey('brokerage', index), value: brokerage.id }, brokerage.name))))),
                    React.createElement(Stack, { spacing: 10 },
                        !newUserMode && (React.createElement(Button, { type: "submit", isLoading: isSubmitting }, "Save Changes")),
                        !!newUserMode && (React.createElement(Button, { type: "submit", isLoading: isSubmitting }, "Create New User")))))),
        React.createElement(ConfirmModal, { title: `Are you sure you want to ${user?.isEnabled ? 'disable' : 'enable'} user?`, ...confirmToggleUserModalProps }),
        React.createElement(ConfirmModal, { title: `Are you sure you want to ${newUserMode ? 'create new' : 'update'} user?`, ...confirmUpsertUserModalProps }),
        React.createElement(ConfirmModal, { title: "Are you sure you want to resend invite email to user?", ...confirmResendInviteModalProps })));
};
