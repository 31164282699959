import { useMutation } from '@tanstack/react-query';
import { logout, removeAuthToken } from '~services';
export const useLogout = (queryClient, key, options) => {
    const logoutMutation = useMutation(async () => {
        if (options?.logoutClientOnly)
            return;
        await logout();
    }, {
        onSuccess: async () => {
            removeAuthToken();
            await queryClient.setQueryData(key, Promise.resolve(undefined));
            options?.onLogout?.();
        },
        onError: (e) => {
            if (!options?.onError) {
                console.error('failed to logout');
                return;
            }
            options.onError(e);
        },
    });
    return {
        logout: () => {
            if (logoutMutation.isLoading)
                return;
            logoutMutation.mutate();
        },
        isLoggingOut: logoutMutation.isLoading,
    };
};
