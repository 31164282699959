import React, { useEffect } from 'react';
import { useStore } from '~hooks';
class ErrorBoundaryComponent extends React.Component {
    componentDidCatch(error, errorInfo) {
        // TODO: log inline temporary
        console.error(`COMPONENT'S ERROR: ${error.message}, ERROR INFO: ${JSON.stringify(errorInfo)}`);
    }
    render() {
        return this.props.children;
    }
}
export const ErrorBoundary = (props) => {
    const { setApplicationFailure } = useStore();
    useEffect(() => {
        window.onerror = (event) => {
            console.error('An application error ocurred', event);
            if (typeof event !== 'string')
                event.preventDefault();
            setApplicationFailure(window.location.pathname);
        };
        window.onunhandledrejection = (event) => {
            console.error('An application error occurred', event);
            event.preventDefault();
            setApplicationFailure(window.location.pathname, event.reason?.message);
        };
        return () => {
            window.onerror = null;
            window.onunhandledrejection = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return React.createElement(ErrorBoundaryComponent, null, props.children);
};
