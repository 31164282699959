import { useMutation } from '@tanstack/react-query';
import { login, setAuthToken } from '~services';
export const useLogin = (queryClient, key, options) => {
    const loginMutation = useMutation(login, {
        onSuccess: async (response) => {
            if (!response?.token) {
                await queryClient.setQueryData(key, Promise.resolve(undefined));
            }
            else {
                setAuthToken(response.token);
                await queryClient.setQueryData(key, Promise.resolve(response));
            }
            await queryClient.refetchQueries(key);
            options?.onLogin?.();
        },
        onError: (e) => {
            if (!options?.onError) {
                console.error('failed to login');
                return;
            }
            options.onError(e);
        },
    });
    return {
        login: (credentials) => {
            if (loginMutation.isLoading)
                return;
            loginMutation.mutate(credentials);
        },
        isLoggingIn: loginMutation.isLoading,
    };
};
