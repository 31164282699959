export var TOccupancy;
(function (TOccupancy) {
    TOccupancy["OCCUPIED"] = "1";
    TOccupancy["VACANT"] = "2";
    TOccupancy["OCCUPANCY_UNKNOWN"] = "3";
})(TOccupancy || (TOccupancy = {}));
export var THasHoa;
(function (THasHoa) {
    THasHoa["UNKNOWN"] = "unknown";
    THasHoa["YES"] = "yes";
    THasHoa["NO"] = "no";
})(THasHoa || (THasHoa = {}));
