import { useMutation, useQueryClient } from '@tanstack/react-query';
import { snackbar } from '~components';
import { getBrokeragesKey } from '~hooks';
import { adminServices } from '~services';
export const useUpdateBrokerage = () => {
    const queryClient = useQueryClient();
    const KEY = getBrokeragesKey();
    const updateBrokerage = async (data) => {
        await adminServices.brokerages.update(data, data.id);
        return { ...data };
    };
    const updateBrokerageMutation = useMutation(updateBrokerage, {
        onSuccess: (updatedBrokerage) => {
            queryClient.setQueryData(KEY, (cachedData) => {
                if (!cachedData)
                    return [updatedBrokerage];
                return [updatedBrokerage, ...cachedData.filter((u) => u.id !== updatedBrokerage.id)];
            });
            snackbar.success('Brokerage successfully updated');
        },
    });
    return {
        updateBrokerage: async (request) => updateBrokerageMutation.mutateAsync(request),
        isUpdating: updateBrokerageMutation.isLoading,
    };
};
