import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
export const usePrompt = (when = false) => {
    const [isOpen, setIsOpen] = useState(false);
    const confirm = useRef(null);
    const context = useRef(null);
    const { navigator } = useContext(NavigationContext);
    const blockNavigator = navigator;
    const next = useCallback(() => {
        confirm.current();
        context.current?.retry?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    useEffect(() => {
        if (!when)
            return;
        const unblock = blockNavigator.block((tx) => {
            setIsOpen(true);
            context.current = tx;
        });
        confirm.current = unblock;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return unblock;
    }, [blockNavigator, when]);
    return [isOpen, setIsOpen, next];
};
