import React, { memo, useMemo } from 'react';
import { ConfirmModal, FileUpload, Text, useModal } from '~components';
import { useFiles } from '~hooks';
const additionalAcceptedFiles = ['.heic', '.heif', '.psd'];
const commonAcceptedFiles = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/tiff',
    'application/pdf',
    'image/bmp',
    'image/webp',
    'text/plain',
    'video/mp4',
    'application/vnd.ms-excel',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    '.doc',
    '.docx',
];
const ListingFilesComponent = ({ isDisabled }) => {
    const [files, isLoading, { uploadFile, removeFile }] = useFiles();
    const [modalProps, confirm] = useModal();
    const acceptedFileTypes = useMemo(() => commonAcceptedFiles.concat(additionalAcceptedFiles).join(','), []);
    const getFileValidationError = (file) => {
        const errorText = 'Only file types png, jpeg, gif, tiff, pdf, bmp, webp, heic, psd, .txt, .mp4, .xlsx, .xls, .csv, .doc, .docx are accepted';
        if (files.find((item) => item.name === file.name)) {
            return 'File can not be uploaded because file with this name and file type already exists in the system.';
        }
        if (file.type === '') {
            if (file.name.endsWith('.heic') || file.name.endsWith('.heif') || file.name.endsWith('.psd'))
                return;
        }
        if (!commonAcceptedFiles.includes(file.type))
            return errorText;
    };
    const onRemove = async (file, onSuccess, onError) => {
        if (!(await confirm()))
            return;
        void removeFile(file, onSuccess, onError);
    };
    return (React.createElement("div", null,
        React.createElement(Text, { colorScheme: "purple", className: "font-medium text-violet-800 " }, "DOCUMENTS"),
        React.createElement(Text, null, "Drag & drop some files here, or click to select files"),
        React.createElement(FileUpload, { ...{ accept: acceptedFileTypes, getFileValidationError, files, isLoading, isDisabled }, onUpload: uploadFile, onRemove: onRemove }),
        React.createElement(ConfirmModal, { title: "Are you sure you want to remove uploaded document?", ...modalProps })));
};
export const ListingFiles = memo(ListingFilesComponent);
